import { initAccountingApi } from 'api';
import { Income } from '@store/income/type';

export type Params = {
  scenarioId?: string;
  personId: string[];
};

export const incomesKey = (scenarioId?: string) => ['incomes', { scenarioId }];

export async function getIncome(params: Params): Promise<Income[]> {
  return initAccountingApi()
    .get<Income[]>('/income', {
      params,
    })
    .then(({ data }) => data || []);
}
export async function getCheckIncome(params: Params) {
  return initAccountingApi()
    .get('income/check', { params })
    .then(({ data }) => data || []);
}
