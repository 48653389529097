import { ExpenseTransaction } from 'types/transaction';
import analytics from '@dvag/dfs-accounting-analytics';
import filterTransactionsByComputable from './filterTransactionsByComputable';

export const isTransactionHidden = (transaction: ExpenseTransaction, isScenarioMode: boolean) => {
  const { meta } = transaction;

  const isConsidered = meta?.isConsidered;
  const productId = meta?.productId;

  return (
    !isScenarioMode &&
    isConsidered === false &&
    Boolean(transaction.contractId || transaction.contractNumber || productId)
  );
};

const getTransactionsTypeDetails = (
  transactions: ExpenseTransaction[],
  isScenarioMode: boolean,
  isHouseholdSelected?: boolean,
): {
  numberOfShownTransactions: number;
  totalTransactionsValue: number;
  numberOfHiddenTransactions: number;
  shownTransactions: ExpenseTransaction[];
} => {
  const filteredTransactionsByComputable = filterTransactionsByComputable({
    isHouseholdSelected,
    transactions,
  });
  if (!isScenarioMode) {
    const { shownTransactions, hiddenTransactions } = filteredTransactionsByComputable.reduce(
      (acc, t) => {
        if (isTransactionHidden(t, isScenarioMode)) {
          acc.hiddenTransactions.push(t);
        } else {
          acc.shownTransactions.push(t);
        }
        return acc;
      },
      { shownTransactions: [], hiddenTransactions: [] } as {
        shownTransactions: ExpenseTransaction[];
        hiddenTransactions: ExpenseTransaction[];
      },
    );
    return {
      numberOfShownTransactions: shownTransactions.length,
      totalTransactionsValue: analytics.computeTotal(shownTransactions as [])
        .monthlyAmountAfterTaxes,
      numberOfHiddenTransactions: hiddenTransactions.length,
      shownTransactions,
    };
  }
  return {
    numberOfShownTransactions: transactions.length,
    totalTransactionsValue: analytics.computeTotal(filteredTransactionsByComputable as [])
      .monthlyAmountAfterTaxes,
    numberOfHiddenTransactions: 0,
    shownTransactions: transactions,
  };
};

export default getTransactionsTypeDetails;
