import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Scenario } from '@store/simulation';
import { updateScenario, scenariosKey } from '@service/scenario';

export const useUpdateScenario = ({ householdId, ...current }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: scenariosKey.byHousehold(householdId),
    mutationFn: (payload: Partial<Scenario>) => updateScenario({ ...current, name: payload.name }),
    onSuccess: () => {
      queryClient.invalidateQueries(scenariosKey.byHousehold(householdId));
      queryClient.getQueryData(scenariosKey.byHousehold(householdId)) as Scenario[];
    },
  });
};
export default useUpdateScenario;
