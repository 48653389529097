import React, { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { i18n } from 'locales';

import Modal from '@components/Modal';
import { ReadWishGoalCards } from '@dvag/dfs-ui-blocks/components';
import PersonSelector from '@components/PersonSelector';
import { useGetPersonWishGoalList } from '@hooks/useGetPersonWishGoalList';
import { usePersons } from '@hooks';

interface Props {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}
type PersonWishGoalDto = {
  comment?: string | null;
  id?: string | null;
  isDesired?: boolean | null;
  name?: string | null;
  order?: number | null;
  personId?: string | null;
  wishGoalId: string;
};
export const filterByisDesired = (data) =>
  data.person.wishGoal.filter((wg: PersonWishGoalDto) => wg.isDesired);
const WishesAndGoalsGalleryModal: FC<Props> = ({ showModal, setShowModal }) => {
  const { persons } = usePersons();
  const [currentPerson, setCurrentPerson] = useState(persons[0].syncId);

  const { data: wishesAndGoals } = useGetPersonWishGoalList(currentPerson, filterByisDesired);

  const handleModalOpen = () => {
    setCurrentPerson(persons[0].syncId);
  };

  const handleModalClose = useCallback(
    (e) => {
      const modalBackgroundClicked = e.detail.reason === 'background';

      if (modalBackgroundClicked) {
        e.detail.preventCloseModal();
        return;
      }
      setShowModal(false);
    },
    [setShowModal],
  );

  return (
    <Modal
      onModalOpened={handleModalOpen}
      onModalClosed={handleModalClose}
      width="xxl"
      height="content"
      visible={showModal}
      data-testid="gallery-modal"
      label={i18n.t('wishesAndGoals.title')}
    >
      <div data-testid="content" slot="content" className="flex flex-col">
        <div className="flex flex-col justify-end md:flex md:flex-wrap md:items-center md:justify-between">
          <PersonSelector selected={currentPerson} handleSelection={setCurrentPerson} />
        </div>
        <div data-testid="container" className="md:mt-8">
          <ReadWishGoalCards
            key={`read-wish-goal-cards-${currentPerson}-${showModal}`}
            list={wishesAndGoals as PersonWishGoalDto[]}
            topDisplayType="show"
          />
        </div>
      </div>
    </Modal>
  );
};

export default WishesAndGoalsGalleryModal;
