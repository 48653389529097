import { i18n } from 'locales';
import SimulationActions from './enums';

interface OverflowMenuOption {
  action: SimulationActions;
  icon: string;
  menuLabel: string;
  dataTestId: string;
  theme: string;
}

const overflowMenuOptions: OverflowMenuOption[] = [
  {
    action: SimulationActions.CREATE_SIMULATION,
    icon: 'pluskreis',
    menuLabel: i18n.t('hhb.simulation.overflowMenu.createSimulation'),
    dataTestId: 'add-new-simulation',
    theme: 'default',
  },
  {
    action: SimulationActions.RESET_SIMULATION,
    icon: 'wiedergabe',
    menuLabel: i18n.t('hhb.simulation.overflowMenu.resetSimulation'),
    dataTestId: 'reset-simulation',
    theme: 'default',
  },
  {
    action: SimulationActions.DELETE_SIMULATION,
    icon: 'muell',
    menuLabel: i18n.t('hhb.simulation.overflowMenu.deleteSimulation'),
    dataTestId: 'delete-simulation',
    theme: 'destructive',
  },
];

export default overflowMenuOptions;
