import { TransactionType } from 'types/transaction';

const findTransactionTypeById = (options: TransactionType[], transactionType: TransactionType) =>
  options.find((temporaryTransactionType) => temporaryTransactionType.id === transactionType.id);

const filteredDropdownOptions = (
  transactionTypes: TransactionType[],
  dropdownTransactionTypes: TransactionType[],
  temporaryTransactionTypes: TransactionType[],
) => {
  const filteredOptions = dropdownTransactionTypes.filter((transactionType) => {
    const isNotTemporaryTransactionType = !findTransactionTypeById(
      temporaryTransactionTypes,
      transactionType,
    );
    const isNotCreatedTransactionType = !findTransactionTypeById(transactionTypes, transactionType);

    return isNotTemporaryTransactionType && isNotCreatedTransactionType;
  });

  return filteredOptions;
};

export default filteredDropdownOptions;
