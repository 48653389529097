import {
  QueryKey,
  useMutation,
  QueryClient,
  useQueryClient,
  UseMutateAsyncFunction,
} from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import {
  PrevCacheDataItem,
  onErrorTransactionsComputationsCacheUpdateForExpense,
  onMutateTransactionsComputationsCacheUpdateForExpense,
} from 'utils';
import useStore from '@store';
import { transactionKey, updateTransaction } from '@service/transaction';
import { ExpenseTransaction } from 'types/transaction';

interface Props {
  householdId: string;
  invalidateTransactionComputationsArgs?: Parameters<(typeof transactionKey)['computations']>['0'];
}
export interface MutationArgType {
  transaction: ExpenseTransaction;
  syncType?: string;
  defaultPersonId: string;
  isContract: boolean;
}

interface UpdatedExpenseTransaction {
  contractId: string;
  transaction: ExpenseTransaction;
}

interface Response {
  isSuccess: boolean;
  isUpdatingTransaction: boolean;
  isUpdateTransactionError: boolean;
  updatedExpenseTransaction: UpdatedExpenseTransaction | undefined;
  updateExpenseTransaction: UseMutateAsyncFunction<unknown, unknown, MutationArgType, unknown>;
}
type IdType = string | number | undefined;

interface UpdateCacheProps {
  queryClient: QueryClient;
  mutationKey: QueryKey;
  id: IdType;
  contractId?: number;
  updatedTransaction: ExpenseTransaction;
}
export const matchTransaction = (
  id: IdType,
  transaction: ExpenseTransaction,
  contractId: number | undefined,
  updatedTransaction: ExpenseTransaction,
) => {
  const isMatchingId = id && transaction.id === updatedTransaction.id;
  const isMatchingScenarioContractId =
    updatedTransaction.scenarioContractId &&
    transaction.id === updatedTransaction.id &&
    updatedTransaction.scenarioContractId === transaction.scenarioContractId;
  const isMatchingContractId = contractId && transaction.contractId === contractId;

  return isMatchingId || isMatchingScenarioContractId || isMatchingContractId;
};
export const updateCacheIfNeeded = (
  queryClient: QueryClient,
  mutationKey: QueryKey,
  response: {
    id: IdType;
    transaction: ExpenseTransaction;
    contractId?: number;
    defaultPersonId: string;
  },
  scenarioId?: string,
) => {
  const { contractId, transaction, id, defaultPersonId } = response;

  const cache =
    (queryClient.getQueryData(mutationKey) as { data: ExpenseTransaction[] })?.data || [];
  const updatedCache = [...cache];
  const isPersonIdChangedInSimulation = defaultPersonId !== transaction.personId && scenarioId;

  const updateConditions = [
    { condition: id !== transaction.id && !transaction.contractId, targetId: id },
    { condition: contractId !== transaction.contractId && !scenarioId, targetId: contractId },
  ];
  updateConditions.forEach(({ condition, targetId }) => {
    if (condition) {
      const index = updatedCache.findIndex(
        (cacheTransaction) =>
          cacheTransaction.id === targetId || cacheTransaction.contractId === targetId,
      );
      if (isPersonIdChangedInSimulation) {
        return queryClient.invalidateQueries(mutationKey);
      }

      if (index !== -1) {
        updatedCache[index] = transaction;

        queryClient.setQueryData(mutationKey, { data: updatedCache });
      }
    }
    return null;
  });
};

export const updateTransactionListCache = ({
  id,
  contractId,
  queryClient,
  mutationKey,
  updatedTransaction,
}: UpdateCacheProps): ExpenseTransaction[] => {
  const cache = queryClient.getQueryData<{ data: ExpenseTransaction[] }>(mutationKey)?.data || [];
  const updatedCache = [...cache];

  const index = cache.findIndex((transaction) =>
    matchTransaction(id, transaction, contractId, updatedTransaction),
  );
  if (index !== -1) {
    updatedCache[index] = updatedTransaction;
    queryClient.setQueryData(mutationKey, { data: updatedCache });
  }

  return cache;
};
export const onSuccess = (
  response: {
    contractId?: number;
    transaction: ExpenseTransaction;
    id: number;
    defaultPersonId: string;
  },
  queryClient: QueryClient,
  mutationKey: QueryKey,
  scenarioId: string | undefined,
  invalidateTransactionComputationsArgs?: {
    householdId: string;
    personId?: string;
    scenarioId?: string;
  },
) => {
  updateCacheIfNeeded(queryClient, mutationKey, response, scenarioId);
  if (!invalidateTransactionComputationsArgs) return;

  queryClient.invalidateQueries(transactionKey.computations(invalidateTransactionComputationsArgs));
};
export const onError = (
  queryClient: QueryClient,
  mutationKey: QueryKey,
  context?: {
    prevTransactionsCache?: ExpenseTransaction[];
    prevTransactionsComputationsCacheArr: PrevCacheDataItem[];
  },
) => {
  onErrorTransactionsComputationsCacheUpdateForExpense({
    queryClient,
    prevTransactionsComputationsCacheArr: context?.prevTransactionsComputationsCacheArr,
  });

  if (context?.prevTransactionsCache) {
    queryClient.setQueryData(mutationKey, { data: context.prevTransactionsCache });
  }
};
export const onSettled = (
  response:
    | {
        contractId?: number;
        transaction: ExpenseTransaction;
        id: number;
      }
    | undefined,
  setIsSubmittingContract: (isSubmitting: boolean, contractId: number) => void,
  scenarioId?: string,
) => {
  setIsSubmittingContract(
    false,
    scenarioId ? (response?.transaction?.contractId as number) : (response?.contractId as number),
  );
};
export const queryParams = (
  scenarioId: string | undefined,
  defaultPersonId: string,
  contractId?: number,
  isContract?: boolean,
  syncType?: string,
) => ({
  scenarioId,
  personId: defaultPersonId,
  syncType,
  isContract: isContract || Boolean(contractId),
  ...(contractId && { contractId }),
});
export const useUpdateExpenseTransaction = (props: Props): Response => {
  const { householdId, invalidateTransactionComputationsArgs } = props;
  const { scenarioId } = useParams();
  const queryClient = useQueryClient();
  const mutationKey = transactionKey.expenses({ householdId, scenarioId });

  const { setIsSubmittingContract } = useStore((state) => state.expenses.actions);

  const { isSuccess, mutateAsync, isLoading, isError, data } = useMutation(
    mutationKey,
    async ({
      transaction,
      syncType,
      defaultPersonId,
      isContract,
    }: MutationArgType): Promise<{
      contractId?: number;
      transaction: ExpenseTransaction;
      id: number;
      defaultPersonId: string;
    }> => {
      const { contractId, scenarioContractId } = transaction;
      setIsSubmittingContract(true, contractId as number);

      const response = await updateTransaction({
        transaction,
        syncType,
        defaultPersonId,
        isContract,
        scenarioId,
        householdId,
      });

      return {
        contractId: scenarioContractId ?? contractId,
        transaction: response.data,
        id: transaction.id as number,
        defaultPersonId,
      };
    },
    {
      onMutate: async ({
        transaction: updatedTransaction,
      }: {
        transaction: ExpenseTransaction;
      }) => {
        await queryClient.cancelQueries(mutationKey);
        const { id, contractId, scenarioContractId } = updatedTransaction;
        const prevTransactionsCache = updateTransactionListCache({
          id,
          queryClient,
          mutationKey,
          updatedTransaction,
          contractId: scenarioContractId ?? contractId,
        });

        const { prevTransactionsComputationsCacheArr } =
          onMutateTransactionsComputationsCacheUpdateForExpense({
            queryClient,
            householdId,
            scenarioId,
            updatedTransaction,
          });

        return {
          prevTransactionsComputationsCacheArr,
          prevTransactionsCache,
        };
      },
      onSuccess: (response) => {
        onSuccess(
          response,
          queryClient,
          mutationKey,
          scenarioId,
          invalidateTransactionComputationsArgs,
        );
        queryClient.invalidateQueries(mutationKey);
      },
      onError: (_error, _, context) => onError(queryClient, mutationKey, context),
      onSettled: (response) => onSettled(response, setIsSubmittingContract, scenarioId),
    },
  );

  return {
    isSuccess,
    isUpdatingTransaction: isLoading,
    isUpdateTransactionError: isError,
    updateExpenseTransaction: mutateAsync,
    updatedExpenseTransaction: data?.transaction as UpdatedExpenseTransaction | undefined,
  };
};

export default useUpdateExpenseTransaction;
