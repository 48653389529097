import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useContinuationStore from './helpers';

export const ContinuationContext = createContext<
  ReturnType<typeof useContinuationStore> | undefined
>(undefined);
interface Props {
  children: React.ReactNode;
}

export const useContinuationContext = () =>
  useContext(ContinuationContext) as ReturnType<typeof useContinuationStore>;

export const ContinuationContextProvider = (props: Props) => {
  const { children } = props;
  const location = useLocation();
  const defaultContinuationContext = useContinuationStore();

  const { unregisterAllHandlers } = defaultContinuationContext;

  const value = useMemo(() => defaultContinuationContext, [defaultContinuationContext]);

  useEffect(() => {
    unregisterAllHandlers();
  }, [location, unregisterAllHandlers]);

  return <ContinuationContext.Provider value={value}>{children}</ContinuationContext.Provider>;
};
