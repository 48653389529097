import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createScenario, scenariosKey } from '@service/scenario';
import { SimulationType } from '@store/simulation';
import { Person } from '@store/person';

interface Props {
  householdId: string;
  person: Person;
  type?: SimulationType;
}

export const useCreateScenario = ({ householdId, person, type }: Props) => {
  const queryClient = useQueryClient();
  const mutationKey = scenariosKey.byHousehold(householdId);
  return useMutation({
    mutationKey,
    mutationFn: () => createScenario(person, householdId, type),
    onSuccess: () => {
      queryClient.invalidateQueries(mutationKey);
    },
  });
};
export default useCreateScenario;
