import React, { FC } from 'react';
import { DxIcon, DxText } from '@dvag/design-system-react';

import { i18n } from 'locales';

const RequiredMarkNote: FC = () => (
  <div className="mt-4 flex items-center" data-testid="required-mark-note">
    <DxIcon icon="state-required" size={24} color="gold" />
    <DxText type="its" className="ml-1">
      {i18n.t('info.requiredMark')}
    </DxText>
  </div>
);

export default RequiredMarkNote;
