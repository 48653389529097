import groupTransactionTypes from 'screens/householdBudget/expenses/helpers/displayGroupColumn/groupTransactionTypes';
import { immer } from 'zustand/middleware/immer';
import { TransactionTypesEnum } from 'utils';
import { TransactionTypesSlice } from './type';

const createTransactionTypesSlice = immer<TransactionTypesSlice>((set) => ({
  transactionTypes: {
    reservedTransactionTypeNames: [],
    actions: {
      setTransactionTypes(transactionTypes, type) {
        set((draftState) => {
          if (TransactionTypesEnum.Hhb === type) {
            draftState.transactionTypes.reservedTransactionTypeNames = Object.keys(
              groupTransactionTypes(
                transactionTypes.filter((transactionType) => transactionType.isHidden),
              ),
            );
          }
        });
      },
    },
  },
}));

export default createTransactionTypesSlice;
