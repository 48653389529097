import { Person } from '@store/person';
import { householdKey, getHouseholdQueryFn } from '@service/household';
import useStore from '@store';

export const personQuery = (householdId) => ({
  queryKey: householdKey.byId(householdId),
  queryFn: async () => getHouseholdQueryFn(householdId as string),
});
const personLoader =
  (queryClient) =>
  async ({ params }): Promise<{ persons: Person[] }> => {
    const { householdId } = params;
    const query = personQuery(householdId);
    const fetchedData = await queryClient(() => {}).fetchQuery(query);
    const queriedData = await queryClient(() => {}).getQueryData(query.queryKey);
    const persons = queriedData || fetchedData;

    const personList =
      persons?.personInHouseholdList?.map((person) => ({
        ...person,
        syncId: person.id,
      })) || [];

    const defaultCurrentPerson = personList.length === 1 ? personList[0].syncId : '';
    useStore.getState().person.actions.setCurrentPerson(defaultCurrentPerson);

    return {
      persons: personList,
    } as { persons: Person[] };
  };

export default personLoader;
