import { Transaction, TransactionType } from 'types/transaction';
import filterTransactionsById from './filterTransactionsById';
import shouldExcludeHiddenTransactionType from './shouldExcludeHiddenTransactionType';

const findAllowedTransactionTypes = (
  transactions: Transaction[],
  transactionType: TransactionType,
) => {
  if (shouldExcludeHiddenTransactionType(transactions, transactionType)) {
    return false;
  }
  const isTypeOwnOrBundle = transactionType.isContract && !transactionType.syncType;
  return isTypeOwnOrBundle
    ? filterTransactionsById(transactions, transactionType).length > 0
    : transactionType;
};
export default findAllowedTransactionTypes;
