import React, { ComponentProps } from 'react';
import { DxModal } from '@dvag/design-system-react';

import { useEscapePreventOnKeyDown, useResetPageScroll } from '@hooks';

interface Props extends ComponentProps<typeof DxModal> {
  children: React.ReactNode;
}

const Modal: React.FC<Props> = ({ children, ...props }) => {
  useEscapePreventOnKeyDown();
  useResetPageScroll();

  return <DxModal {...props}>{children}</DxModal>;
};

export default Modal;
