import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { createPersonSlice, PersonSlice } from './person';
import { createSimulationSlice, SimulationSlice } from './simulation';
import { createFeedbackSlice, FeedbackSlice } from './feedback';
import { createNavigationSlice, NavigationSlice } from './navigation';
import { createFinancialGradeSlice, FinancialGradeSlice } from './financialGrade';
import { ExpensesSlice, createExpensesSlice } from './expenses';
import { TransactionTypesSlice, createTransactionTypesSlice } from './transactionType';

export type StoreState = FinancialGradeSlice &
  FeedbackSlice &
  NavigationSlice &
  SimulationSlice &
  PersonSlice &
  ExpensesSlice &
  TransactionTypesSlice;

const middlewares = (f) => devtools(immer<StoreState>(f));

const useStore = create<StoreState>()(
  middlewares((set, get, api) => ({
    ...createFinancialGradeSlice(set, get, api),
    ...createFeedbackSlice(set, get, api),
    ...createNavigationSlice(set, get, api),
    ...createSimulationSlice(set, get, api),
    ...createPersonSlice(set, get, api),
    ...createExpensesSlice(set, get, api),
    ...createTransactionTypesSlice(set, get, api),
  })),
);

export default useStore;
