import { useParams } from 'react-router-dom';

import { Scenario } from '@store/simulation';
import { useScenarios } from '@hooks/useScenarios';

interface Response {
  isError: boolean;
  scenario: Scenario;
  isLoadingScenario: boolean;
}

export const useScenario = (): Response => {
  const { scenarioId } = useParams<{ scenarioId: string }>();

  const select = (data: Scenario[]) =>
    data.find((scenario: Scenario) => scenario.id === scenarioId) as Scenario;

  const { data: scenarioList, isError, isFetching: isFetchingScenarioList } = useScenarios();

  const scenario = select(scenarioList || []);

  return {
    isError,
    scenario,
    isLoadingScenario: isFetchingScenarioList,
  };
};

export default useScenario;
