import getDateDetails from './getDateDetails';
import { i18n } from '../../../locales';
import addZeroPrefix from './addZeroPrefix';
import simpleDateFormat from './simpleDateFormat';

type DateTypeResults = {
  validation: {
    value: Date;
    errorMessage: string;
  };
  value: string;
};

type Result = {
  minDate: DateTypeResults;
  maxDate: DateTypeResults;
};

const getDateResultsByType = ({
  dateDetails,
  validationDateDetails,
  type,
}: {
  type: 'minDate' | 'maxDate';
  dateDetails: {
    year: number;
    month: number;
    day: number;
  };
  validationDateDetails: {
    year: number;
    month: number;
    day: number;
  };
}) => {
  const dateValue = `${dateDetails.year}-${addZeroPrefix(dateDetails.month)}-${addZeroPrefix(dateDetails.day)}`;
  const dateValidationValue = new Date(dateValue);
  dateValidationValue.setHours(0, 0, 0, 0);

  const dateValidationErrorMessage =
    type === 'minDate'
      ? i18n.t('validation.minDate', {
          value: simpleDateFormat(validationDateDetails),
        })
      : i18n.t('validation.maxDate', {
          value: simpleDateFormat(validationDateDetails),
        });

  return {
    dateValue,
    dateValidationValue,
    dateValidationErrorMessage,
  };
};

const getMinAndMaxDate = ({
  minDate,
  maxDate,
  useLowestMinDate = true,
}: {
  minDate: Date;
  maxDate?: Date;
  useLowestMinDate?: boolean;
}): Result => {
  let lowestMinData = minDate;

  // We are always comparing dates without time, so we need to reset the time to 00:00:00
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  minDate.setHours(0, 0, 0, 0);
  if (useLowestMinDate) {
    lowestMinData = minDate > today ? today : minDate;
  }

  const minDateDetails = getDateDetails({ date: lowestMinData });
  const minDateDetailsDayBefore = getDateDetails({
    date: lowestMinData,
    dayOffset: -1,
  });

  const minDateTypeResults = getDateResultsByType({
    dateDetails: minDateDetails,
    validationDateDetails: minDateDetailsDayBefore,
    type: 'minDate',
  });

  const maxDateDetailsProps = maxDate ? { date: maxDate } : { date: today, yearOffset: 100 };
  const maxDateDetails = getDateDetails(maxDateDetailsProps);
  const maxDateDetailsDayAfter = getDateDetails({
    ...maxDateDetailsProps,
    dayOffset: +1,
  });
  const maxDateTypeResults = getDateResultsByType({
    dateDetails: maxDateDetails,
    validationDateDetails: maxDateDetailsDayAfter,
    type: 'maxDate',
  });

  return {
    minDate: {
      validation: {
        value: minDateTypeResults.dateValidationValue,
        errorMessage: minDateTypeResults.dateValidationErrorMessage,
      },
      value: minDateTypeResults.dateValue,
    },
    maxDate: {
      validation: {
        value: maxDateTypeResults.dateValidationValue,
        errorMessage: maxDateTypeResults.dateValidationErrorMessage,
      },
      value: maxDateTypeResults.dateValue,
    },
  };
};
export default getMinAndMaxDate;
