import { Path, PathValue, UseFormSetValue } from 'react-hook-form';

const setValueFn = <T extends Record<string, unknown>>(
  setValue: UseFormSetValue<T>,
  fieldName: Path<T>,
  resourceFieldValue: PathValue<T, Path<T>>,
) => {
  setValue(fieldName, resourceFieldValue, {
    shouldValidate: true,
    shouldDirty: true,
    shouldTouch: true,
  });
};
export default setValueFn;
