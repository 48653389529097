import React from 'react';
import { useParams } from 'react-router-dom';
import {
  ComparisonDropdownValuesEnum,
  getComparisonSessionStorage,
  updateComparisonSessionStorage,
} from '@dvag/dfs-financial-comparison';
import { DxButton } from '@dvag/design-system-react';

import { i18n } from 'locales';
import { useNavigation } from 'hooks';

type Props = {
  className: string;
};

const GoToComparisonButton = ({ className }: Props) => {
  const { householdId, scenarioId } = useParams();
  const { navigateTo } = useNavigation();
  const handleGoToComparison = () => {
    const params = { householdId, scenarioId };
    const leftSide = getComparisonSessionStorage('simulationMode')
      ? scenarioId
      : ComparisonDropdownValuesEnum.Actual;

    updateComparisonSessionStorage('comparisonFilter', {
      leftSide,
      rightSide: ComparisonDropdownValuesEnum.Optimum,
    });

    navigateTo('simulation.comparison', params);
  };
  return (
    <>
      <DxButton
        stretch
        unfocusable
        type="primary-m"
        icon="pfeil-rechts"
        iconposition="right"
        onClick={handleGoToComparison}
        className={`${className} md:hidden`}
        data-testid="go-to-comparison-button-1"
        label={i18n.t('hhb.expenses.goToComparison')}
      />
      <DxButton
        unfocusable
        type="primary-m"
        icon="pfeil-rechts"
        iconposition="right"
        onClick={handleGoToComparison}
        data-testid="go-to-comparison-button-2"
        className={`${className} hidden md:block`}
        label={i18n.t('hhb.expenses.goToComparison')}
      />
    </>
  );
};

export default GoToComparisonButton;
