import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { i18n } from 'locales';

import Modal from '@components/Modal';
import { DxCard, DxCardImage, DxGrid, DxText } from '@dvag/design-system-react';
import { GalleryOption } from './config/galleryOptions';

interface Props {
  options: GalleryOption[];
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}

const GalleryModal: FC<Props> = (props) => {
  const { options, showModal, setShowModal } = props;

  const handleModalClose = useCallback(
    (e) => {
      const modalBackgroundClicked = e.detail.reason === 'background';

      if (modalBackgroundClicked) {
        e.detail.preventCloseModal();
        return;
      }
      setShowModal(false);
    },
    [setShowModal],
  );

  return (
    <Modal
      onModalClosed={handleModalClose}
      width="xxl"
      height="content"
      visible={showModal}
      data-testid="gallery-modal"
      label={i18n.t('general.moreInformation')}
    >
      <div slot="content">
        <DxGrid
          base="page"
          mq1="12/*"
          mq2="12/*"
          mq3="12/*"
          mq4="4-4-4"
          mq5="4-4-4"
          className="gap-8"
        >
          {options.map((option, id) => (
            <DxCard data-testid="gallery-card" key={option.title}>
              <DxText
                data-testid={`${option.dataTestId}-title-${id}`}
                className="flex h-16 items-center px-6"
                color="gray-66"
                type="ps"
              >
                {option.title}
              </DxText>
              <DxCardImage
                fit="proportional"
                url={option.image}
                data-testid={`${option.dataTestId}-image-${id}`}
              />
              <DxText
                data-testid={`${option.dataTestId}-content-${id}`}
                className="p-6"
                color="gray-66"
                type="ps"
              >
                {option.content}
              </DxText>
            </DxCard>
          ))}
        </DxGrid>
      </div>
    </Modal>
  );
};

export default GalleryModal;
