import { housingInfoQuery, rentRealEstateQuery } from '@service/housing';
import { Person } from '@store/person';

const fetchDataArray = async (promises, property, queryClient) =>
  (
    await Promise.all(promises.map((promise) => queryClient().fetchQuery(promise[property])))
  ).flat();

const loadHousing = (queryClient, loadPersons) => async (router) => {
  const currentLocation = router.params['*'];
  if (currentLocation !== 'wohnsituation') return null;

  const fetchPersons = loadPersons(queryClient);
  const { persons } = await fetchPersons(router);

  const promises = persons.map((person: Person) => ({
    housingInfo: housingInfoQuery(person.id),
    rentRealEstate: rentRealEstateQuery(person.id),
  }));

  const [housingInfoDataArray] = await Promise.all([
    fetchDataArray(promises, 'housingInfo', queryClient),
  ]);
  return {
    housingInformationData: housingInfoDataArray,
  };
};

export default loadHousing;
