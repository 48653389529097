import { useMemo } from 'react';

import { getContext } from '@dvag/dfs-orchestrator-client';

type Permission =
  | 'canAccessFavorites'
  | 'canUploadDocument'
  | 'canAccessSectionReport'
  | 'canAccessRecommendationPage'
  | 'canAccessTeambuildingPage'
  | 'canAccessBausparen'
  | 'canAccessShoppingCart'
  | 'canAccessRentenplaner'
  | 'canAccessBalanceSheet'
  | 'canAccessVPD'
  | 'permission.vpd.app-web-vp-digital.n-simulationen';

type Permissions = { [key in Permission]: boolean };

export default function usePermissions() {
  const { permissions } = getContext();

  return useMemo(() => permissions, [permissions]) as unknown as Permissions;
}
