import {
  PersonWishGoalMutationTransactionInputDto,
  useUpdatePersonWishGoalListMutation,
} from '@graphql/generates.person';
import { getGQLClient } from '@graphql';

const useUpdatePersonWishGoalList = (personId: string) => {
  const wishGoalMutation = useUpdatePersonWishGoalListMutation(getGQLClient('person'));

  return {
    ...wishGoalMutation,
    mutateAsync: (transactionList: PersonWishGoalMutationTransactionInputDto[]) =>
      wishGoalMutation
        .mutateAsync({
          wishGoalInput: {
            personId,
            transactionList: transactionList.map((transactionItem) => {
              const { operation, data } = transactionItem;
              return {
                operation,
                data: {
                  wishGoalId: data.wishGoalId,
                  investmentTerm: data.investmentTerm || null,
                  isDesired: data.isDesired,
                  order: data.order,
                  comment: data.comment,
                },
              };
            }),
          },
        })
        .then((output) => output.wishGoal.transactionList),
  };
};

export default useUpdatePersonWishGoalList;
