import { Transaction } from 'types/transaction';

export const defaultSelect = (response) => ({
  data: !response?.data?.conflict
    ? response?.data?.filter?.((transaction: Transaction) => !transaction.deletedAt) || []
    : response?.data || [],
});

export const isConflict = (data) => !!data?.conflict?.length;
export const groupedConflicts = (conflict): { [key: string]: Transaction[] } =>
  conflict?.reduce(
    (acc, transaction) => ({
      ...acc,
      [transaction.parentTransactionId]: [
        ...(acc[transaction.parentTransactionId] ?? []),
        transaction,
      ],
    }),
    {},
  );

export const deleteConflictingSplitContracts = (conflict: Transaction[], deleteSplitContract) => {
  Object.entries(groupedConflicts(conflict)).forEach(
    ([parentTransactionId, [transaction]]: [string, Transaction[]]) =>
      deleteSplitContract({
        splitContractId: parentTransactionId,
        personId: transaction.personId,
        householdId: transaction.householdId,
      }),
  );
};

export const handleConflict = ({
  response,
  enabled,
  queryClient,
  deleteSplitContract,
  queryKey,
}) => {
  if (isConflict(response) && enabled) {
    const { conflict, expenses } = response;
    const filteredTransactions =
      expenses?.filter((cachedData) =>
        conflict.some((contract) => cachedData.parentTransactionId !== contract.id),
      ) || [];

    deleteConflictingSplitContracts(conflict, deleteSplitContract);

    queryClient.setQueryData(queryKey, { data: filteredTransactions });
  }
};
