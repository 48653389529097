import analytics from '@dvag/dfs-accounting-analytics';

import { getLocale } from 'locales';
import { ComputedExpenseTransaction, ExpenseTransaction } from 'types/transaction';

const mapAndSortTransactions = (transactions: ExpenseTransaction[]): ComputedExpenseTransaction[] =>
  transactions
    .map((t) => {
      const computedTransactionTotal = analytics.computeTotal([
        t,
      ] as unknown as []).monthlyAmountAfterTaxes;

      const formattedTransactionTotal = analytics.toCurrency(computedTransactionTotal, getLocale());
      return {
        ...t,
        computedTransactionTotal,
        formattedTransactionTotal,
      };
    })
    .sort((a, b) => a.computedTransactionTotal - b.computedTransactionTotal);

export default mapAndSortTransactions;
