import { FieldValues } from 'react-hook-form';
import { UseMutateAsyncFunction, useMutation, useQueryClient } from '@tanstack/react-query';

import { TransactionTypesEnum } from 'utils';
import { Transaction } from 'types/transaction';

import { initAccountingApi } from 'api';
import { useParams } from 'react-router-dom';
import { transactionKey } from '@service/transaction';

type Variables = {
  id: string;
  contractId?: number;
  isContract: boolean;
  contractNumber: string;
  requestData: FieldValues;
};

type Data = Transaction[];
interface ResponseType {
  isLoading: boolean;
  isSuccess: boolean;
  isCreateTransactionError: boolean;
  data: Data;
  createSplitContract: UseMutateAsyncFunction<unknown, unknown, Variables, unknown>;
}

interface PropType {
  personId: string;
  householdId: string;
}

const useCreateSplitContract = ({ personId, householdId }: PropType): ResponseType => {
  const queryClient = useQueryClient();
  const mutationKey = [`split-contract`, { personId }];

  const { scenarioId } = useParams();

  const queryFn = ({
    id,
    contractId,
    contractNumber,
    requestData,
    isContract,
  }: Variables): Promise<{ data: Data }> => {
    const { transactionTypeIdEdit } = requestData;
    const amountAfterTaxesSplit = requestData['amountAfterTaxes-split'];
    const displayGroupIdSplit = requestData['displayGroupId-split'];
    const transactionTypeNameSplit = requestData['transactionTypeName-split'];
    const url = '/expense/split';

    const newSplitContract = {
      amountAfterTaxes: amountAfterTaxesSplit,
      displayGroupId: displayGroupIdSplit,
      transactionTypeShortName: transactionTypeNameSplit,
      transactionTypeName: transactionTypeNameSplit,
    };

    const splitContractPayloadEdit = {
      amountAfterTaxes: requestData['amountAfterTaxes-edit'],
      transactionTypeId: transactionTypeIdEdit,
    };
    const postPayload = {
      initialExpenseId: contractId ?? id,
      expensesToSplit: [newSplitContract, splitContractPayloadEdit],
    };
    return initAccountingApi().post(url, postPayload, {
      params: {
        scenarioId,
        householdId,
        isContract,
        vertragsnummerMitUnterdepotnummer: contractNumber,
        personId,
      },
    });
  };
  const { mutateAsync, isLoading, isError, isSuccess, data } = useMutation(queryFn, {
    mutationKey,
    onSuccess: () => {
      const transactionTypesQueryKey = [
        'transaction-types',
        { householdId, type: TransactionTypesEnum.Hhb, scenarioId },
      ];
      const queryKey = transactionKey.expenses({ householdId, scenarioId: undefined });
      queryClient.invalidateQueries(queryKey);
      queryClient.invalidateQueries(transactionTypesQueryKey);
    },
  });

  return {
    isLoading,
    isSuccess,
    isCreateTransactionError: isError,
    createSplitContract: mutateAsync,
    data: (data ? data.data : {}) as Data,
  };
};

export default useCreateSplitContract;
