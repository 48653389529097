import React, { useMemo } from 'react';
import { appNames } from '@dvag/dfs-constant-config';
import { useLocation } from 'react-router';
import { navigateNext as navigate } from '@dvag/dfs-orchestrator-client/messengers';

import { getDefaultPath } from 'utils';
import { useContinuationContext } from 'providers';

import SimulationEnd from './SimulationEndButton';
import GoToComparison from './GoToComparisonButton';

const Simulation = () => {
  const location = useLocation();
  const defaultPath = useMemo(() => getDefaultPath(location.pathname), [location.pathname]);
  const { getContinuationHandler } = useContinuationContext();
  const exitSimulation = () => {
    navigate(`/${appNames.accounting}${defaultPath}`, { resetWorkflow: true });
  };

  return (
    <div className="flex flex-col items-center gap-4 md:flex-row">
      <SimulationEnd
        className="order-2 md:order-1"
        onClick={getContinuationHandler(exitSimulation)}
      />
      <GoToComparison className="order-3 m-auto md:order-2 md:ml-auto md:mr-1" />
    </div>
  );
};
export default Simulation;
