import { initAccountingApi } from 'api';
import {
  HousingInformation,
  OwnRealEstateType,
  RentRealEstateType,
} from 'types/housingInformation';

import { defaultBuyRealEstateForm } from '../@app/basicData/housing/helpers';

export const ownRealEstateKey = (personId: string) => ['immobilien', { personId }];
export const rentRealEstateKey = (personId: string) => ['mieten', { personId }];
export const housingInfoKey = (personId: string) => ['housing-information', { personId }];

export const housingInfoQuery = (personId) => ({
  queryKey: housingInfoKey(personId),
  queryFn: () =>
    initAccountingApi()
      .get<HousingInformation>('v3/housing-information', { params: { personId } })
      .then(({ data }) => data),
});
export const createHousingInfo = async (requestData: HousingInformation, personId: string) => {
  const payload = { ...requestData };
  delete payload.id;
  delete payload.hasBuyRealEstate;

  return initAccountingApi()
    .post<HousingInformation>('v3/housing-information', { ...payload, personId })
    .then(({ data }) => data);
};
export const updateHousingInfoQuery = async (requestData: HousingInformation, personId: string) => {
  const { id, ...housingData } = requestData;
  const payload = { ...defaultBuyRealEstateForm, ...housingData, personId };
  delete payload.createdAt;
  delete payload.updatedAt;
  delete payload.hasBuyRealEstate;
  return initAccountingApi()
    .patch<HousingInformation>(`v3/housing-information/${id}`, payload)
    .then(({ data }) => data);
};

export const rentRealEstateQuery = (personId: string) => ({
  queryKey: rentRealEstateKey(personId),
  queryFn: () =>
    initAccountingApi()
      .get<RentRealEstateType[]>('v3/housing-information/mieten', { params: { personId } })
      .then(({ data }) => data),
});
export const ownEstateQuery = (personId: string) => ({
  queryKey: ownRealEstateKey(personId),
  queryFn: () =>
    initAccountingApi()
      .get<OwnRealEstateType[]>('v3/housing-information/immobilien', { params: { personId } })
      .then(({ data }) => data),
});
