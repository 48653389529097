import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { Scenario } from '@store/simulation';
import { getScenariosQueryFn, scenariosKey } from '@service/scenario';

type Props = {
  enabled?: boolean;
  staleTime?: number;
  onSuccess?: (scenario: Scenario[]) => void;
};

const compareScenariosByLatestUpdatedAt = (scenarioA: Scenario, scenarioB: Scenario) =>
  new Date(scenarioB.updatedAt).getTime() - new Date(scenarioA.updatedAt).getTime();

const sortScenariosByLatestUpdatedAt = (scenarios: Scenario[]) => {
  const sortedScenarios = [...scenarios];
  sortedScenarios.sort(compareScenariosByLatestUpdatedAt);
  return sortedScenarios;
};

export const useScenarios = ({ onSuccess, enabled, staleTime }: Props = {}) => {
  const { householdId } = useParams();
  return useQuery(scenariosKey.byHousehold(householdId), () => getScenariosQueryFn(householdId), {
    select: (data: Scenario[]) => {
      if (data.constructor.name === 'Object') return [];
      const sortedScenariosByLatestUpdatedAt = sortScenariosByLatestUpdatedAt(data);
      return sortedScenariosByLatestUpdatedAt.map((scenario, order) => ({
        ...scenario,
        order,
      }));
    },
    enabled,
    staleTime: typeof staleTime === 'number' ? staleTime : Infinity,
    onSuccess,
  });
};

export default useScenarios;
