import React, { FC, useState } from 'react';
import { i18n, getLocale } from 'locales';
import analytics from '@dvag/dfs-accounting-analytics';
import { DxButton, DxText } from '@dvag/design-system-react';

import { Person } from '@store/person';
import { DeleteAlert, RequiredMarkNote } from 'components';

import { useParams } from 'react-router-dom';
import { ContractDetailsParams } from '@store/contract';
import { useDeleteSplitContract, useNavigation } from 'hooks';

interface Product {
  amount: number;
  company: string;
  productType: string;
}
interface Props {
  person: Person;
  product: Product;
  isSplitted: boolean;
  isSplitContractLoading: boolean;
  splitId: string;
}

const SplitProductPageHeader: FC<Props> = (props) => {
  const { product, person, isSplitContractLoading, isSplitted, splitId } = props;
  const { amount, company, productType } = product;
  const personId = person.syncId;
  const { navigateTo } = useNavigation();
  const { householdId } = useParams<ContractDetailsParams>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
  const { deleteSplitContract, isError } = useDeleteSplitContract();

  const formattedProductAmount = analytics.toCurrency(amount ?? 0, getLocale());

  const handleDelete = async () => {
    toggleDeleteModal();
    await deleteSplitContract({ splitContractId: splitId, personId });

    if (isError) return;

    navigateTo('analysis.expenses', { householdId });
  };
  return (
    <>
      <div data-testid="split-product-header" className="mb-7 flex flex-row justify-between p-0">
        <div className="flex flex-col capitalize">
          <DxText data-testid="header-title" type="pb-bold" color="gray-83">
            {i18n.t('hhb.splitProduct.header.title')}
          </DxText>
          <DxText type="it" color="paragraph" data-testid="header-subtitle" className="mt-4">
            {company} - {productType}
          </DxText>
          <RequiredMarkNote />
        </div>
        <div>
          <div className="ml-2 flex flex-col text-end">
            <DxText data-testid="header-amount" type="it" color="headline">
              {i18n.t('hhb.splitProduct.header.amountInKI')}
            </DxText>
            <DxText data-testid="formatted-header-amount" type="its">
              {i18n.t('hhb.splitProduct.header.amount', {
                amount: formattedProductAmount,
              })}
            </DxText>
          </div>
          {isSplitted && !isSplitContractLoading && (
            <DxButton
              type="text"
              icon="muell"
              iconposition="left"
              onClick={toggleDeleteModal}
              data-testid="SC-show-delete-modal"
              label={i18n.t('hhb.splitProduct.deleteModal.deleteSplitContract')}
            />
          )}
        </div>
      </div>
      <DeleteAlert
        handleDelete={handleDelete}
        toggleDeleteModal={toggleDeleteModal}
        title={i18n.t('hhb.splitProduct.deleteModal.attentionDeleteSplitContract')}
        contentText={i18n.t('hhb.splitProduct.deleteModal.deleteSplitContractDisclaimer')}
        visible={showDeleteModal}
      />
    </>
  );
};

export default SplitProductPageHeader;
