import React from 'react';
import { DxAlert } from '@dvag/design-system-react';

import { useResetPageScroll } from '@hooks';

interface Props extends React.ComponentProps<typeof DxAlert> {
  children: React.ReactNode;
  visible: boolean;
}

const Alert: React.FC<Props> = ({ children, visible, ...props }) => {
  useResetPageScroll();

  return (
    <DxAlert visible={visible} {...props}>
      {children}
    </DxAlert>
  );
};

export default Alert;
