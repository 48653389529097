import { useCallback, useState } from 'react';

const useObservableList = <T>() => {
  const [observableDictionary, setObservableDictionary] = useState<{ [id: string]: T }>({});
  const observableList = Object.keys(observableDictionary).map((key) => observableDictionary[key]);

  const register = useCallback(() => {
    const id = window.crypto.randomUUID();
    return (subject: T) => {
      setObservableDictionary((prev) => ({ ...prev, [id]: subject }));
    };
  }, []);

  const unregisterAll = useCallback(() => setObservableDictionary({}), []);

  return { register, unregisterAll, observableList };
};

export default useObservableList;
