import { immer } from 'zustand/middleware/immer';

import { i18n } from 'locales';
import { FinancialGradeSlice, SliderSection } from './type';
import { positionByGrade, gradeFromPosition } from './defaultValues';

const createFinancialGradeSlice = immer<FinancialGradeSlice>(() => ({
  financialGrade: {
    config: [
      {
        title: i18n.t('financialGrade.slider.rateFinancialGrade'),
        type: SliderSection.CURRENT_GRADE,
      },
      {
        title: i18n.t('financialGrade.slider.desiredGrade'),
        type: SliderSection.DESIRED_GRADE,
      },
    ],
    gradeLabels: Array.from({ length: 6 }, (__, i) => i + 1).reverse(),
    positionByGrade: (position: string | null) => positionByGrade[position || '-'],
    gradeFromPosition: (grade: string) => gradeFromPosition[grade],
  },
}));

export default createFinancialGradeSlice;
