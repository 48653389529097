import { useCallback, useEffect } from 'react';

const useEscapePreventOnKeyDown = () => {
  const escPrevent = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      event.preventDefault();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escPrevent);
    return () => {
      document.removeEventListener('keydown', escPrevent);
    };
  }, [escPrevent]);
};

export default useEscapePreventOnKeyDown;
