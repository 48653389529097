import React from 'react';
import { useParams, generatePath, Navigate } from 'react-router-dom';

const NavigateWithParams: React.FC<{ to: string }> = ({ to }) => {
  const params = useParams();
  const path = generatePath(to as string, params);

  return <Navigate to={path} replace />;
};

export default NavigateWithParams;
