import { getGQLClient } from '@graphql';
import {
  GetPersonWishGoalListQuery,
  useGetPersonWishGoalListQuery,
} from '@graphql/generates.person';

const defaultSelect = (data: GetPersonWishGoalListQuery) => data.person.wishGoal;

export const useGetPersonWishGoalList = (personId: string, select = defaultSelect) => {
  const query = useGetPersonWishGoalListQuery(
    getGQLClient('person'),
    {
      personId,
    },
    {
      enabled: !!personId,
      select,
      keepPreviousData: true,
    },
  );
  return { ...query, data: query.data || [] };
};

export default useGetPersonWishGoalList;
