import { AxiosError } from 'axios';
import { GeneralError, NotFoundError } from '@errors';
import ApiClient from '@dvag/dfs-api-client';
import { getApiUrls } from '@dvag/dfs-constant-config';
import getEnvConfig from 'api/envConfig';
import { getToken, getContext } from '@dvag/dfs-orchestrator-client';
import isErrorBadRequest from './isErrorBadRequest';

const ENV_API_URLS = getApiUrls(getEnvConfig().env);
export type Api = keyof typeof ENV_API_URLS;

const apiUrls: { [key in Api]?: string } = {
  person: ENV_API_URLS.person,
  accounting: ENV_API_URLS.accounting,
};

const createApiInstance = (service: Api) => {
  const config = {
    baseURL: apiUrls[service],
    headers: {
      Accept: 'application/json',
    },
  };

  return () => {
    const apiClient = new ApiClient({
      config,
      options: {
        getContext,
        getToken,
        hasDefaultRequestInterceptors: true,
      },
    });
    apiClient.addResponseInterceptor(
      (response) => response,
      (error: AxiosError) => {
        if (isErrorBadRequest(error)) {
          return Promise.reject(new NotFoundError(error));
        }
        return Promise.reject(new GeneralError(error));
      },
    );

    return apiClient;
  };
};

const initAccountingApi = createApiInstance('accounting');
const initPersonApi = createApiInstance('person');

export { initAccountingApi, initPersonApi, createApiInstance };
