import { useState, useEffect } from 'react';

export const GetWindowSize: () => { width: number; height: number } = () => {
  const getSize = () => ({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    const handleResize = () => setWindowSize(getSize());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export const phoneWidth = 476;
export const mobileWidth = 760;
export const tabletWidth = 1024;
export const customTabletWidth = 960;

export const checkIsMobile = (triggerValue = mobileWidth): boolean => {
  const { width } = GetWindowSize();
  // As discussed with Rouven between 425px and 767px we might need to aggregate more fields on the same row
  // he will only provide the designs for the mobile version below 425px > 375px
  return width <= triggerValue;
};
