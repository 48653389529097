import React, { FC } from 'react';
import { DxOverflowMenu, DxOverflowMenuItem } from '@dvag/design-system-react';
import { Scenario } from '@store/simulation';
import { selectSimulationActions } from '@store/simulation/selector';
import useStore from '@store';
import { OverflowMenuItemTheme } from '@dvag/design-system';
import SimulationActions from './config/enums';
import overflowMenuOptions from './config/overflowMenuOptions';

interface Props {
  simulation: Scenario;
  startSimulation: () => void;
}

const OverflowMenu: FC<Props> = (props) => {
  const { simulation, startSimulation } = props;
  const { update } = useStore(selectSimulationActions);

  const handleOnSelection = (value: SimulationActions) => {
    switch (value) {
      case SimulationActions.CREATE_SIMULATION:
        return startSimulation();
      case SimulationActions.DELETE_SIMULATION:
        return update(simulation, 'delete');
      case SimulationActions.RESET_SIMULATION:
        return update(simulation, 'reset');
      default:
        return null;
    }
  };

  return (
    <DxOverflowMenu
      align="right"
      data-testid="n-sim-menu"
      className="md:flex-end mt-0 md:flex md:flex-col md:justify-end md:self-end"
      onSelection={(e) => handleOnSelection(e.detail.value as SimulationActions)}
    >
      {overflowMenuOptions.map(({ action, icon, menuLabel, dataTestId, theme }) => (
        <DxOverflowMenuItem
          key={action}
          icon={icon}
          data-testid={dataTestId}
          value={action}
          label={menuLabel}
          theme={theme as OverflowMenuItemTheme}
        />
      ))}
    </DxOverflowMenu>
  );
};

export default OverflowMenu;
