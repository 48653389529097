import { DisplayGroupType } from 'types/displayGroup';
import { TransactionType } from 'types/transaction';

const findDisplayGroup = (
  displayGroups: DisplayGroupType[],
  transactionType: TransactionType | undefined,
) => {
  if (!transactionType) return undefined;
  return displayGroups.find((displayGroup) =>
    transactionType.displayGroupParentId
      ? displayGroup.id === transactionType.displayGroupParentId
      : displayGroup.id === transactionType.displayGroupId,
  );
};

export default findDisplayGroup;
