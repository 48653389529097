import React, { FC } from 'react';
import Alert from '@components/Alert';
import { DxButton, DxText } from '@dvag/design-system-react';

import { i18n } from 'locales';

interface PropType {
  visible: boolean;
  title: string;
  contentText: string;
  handleDelete: (e) => void;
  toggleDeleteModal: (e) => void;
  onClick?: (e) => void;
  disableConfirmDelete?: boolean;
}

const DeleteAlert: FC<PropType> = ({
  visible,
  title,
  contentText,
  handleDelete,
  toggleDeleteModal,
  disableConfirmDelete,
  onClick,
}) => (
  <Alert
    visible={visible}
    actionsdirection="row"
    titlestring={title}
    data-testid="delete-alert"
    onClick={onClick}
  >
    <DxText data-testid="delete-modal-text" slot="custom" type="Infotext-Standard">
      {contentText}
    </DxText>
    <DxButton
      slot="action"
      type="primary-s"
      onClick={handleDelete}
      data-testid="confirm-delete"
      disabled={disableConfirmDelete}
      label={i18n.t('general.delete')}
    />
    <DxButton
      type="text"
      slot="action"
      onClick={toggleDeleteModal}
      data-testid="cancel-delete"
      label={i18n.t('general.cancel')}
    />
  </Alert>
);

DeleteAlert.defaultProps = {
  disableConfirmDelete: false,
  onClick: undefined,
};

export default DeleteAlert;
