import Cookies from 'universal-cookie';

const getCookieName = {
  twoAccountModelMainPageContentShown: (householdId: string) =>
    `${householdId}_twoAccountModelContentShown`,
};

interface CookieValueByName {
  twoAccountModelMainPageContentShown: boolean;
}

type FirstCookieName = (typeof getCookieName)['twoAccountModelMainPageContentShown'];
type CookieSetOptions = Parameters<Cookies['set']>['2'];
type CookieGetOptions = Parameters<Cookies['get']>['1'];

export const setCookie = <
  CookieNameKey extends keyof typeof getCookieName,
  CookieNameParams extends Parameters<(typeof getCookieName)[CookieNameKey]>,
>(
  args: {
    key: CookieNameKey;
    value: CookieValueByName[CookieNameKey];
    options?: CookieSetOptions;
  } & (CookieNameParams extends [] ? unknown : { params: CookieNameParams }),
) => {
  const cookies = new Cookies();

  const getCookieNameFn = getCookieName[args.key] as FirstCookieName;
  const { params } = args;
  const cookieName = getCookieNameFn(...params);
  return cookies.set(cookieName, args.value);
};

export const getCookie = <
  CookieNameKey extends keyof typeof getCookieName,
  CookieNameParams extends Parameters<(typeof getCookieName)[CookieNameKey]>,
>(
  args: {
    key: CookieNameKey;
    options?: CookieGetOptions;
  } & (CookieNameParams extends [] ? unknown : { params: CookieNameParams }),
): CookieValueByName[CookieNameKey] => {
  const cookies = new Cookies();

  const getCookieNameFn = getCookieName[args.key] as FirstCookieName;
  const { params } = args;
  const cookieName = getCookieNameFn(...params);

  return cookies.get(cookieName, args.options);
};
