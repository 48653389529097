import React from 'react';
import { AppProvider } from 'providers';
import { NotificationToast } from '@components';

import Router from './Router';

const App = () => (
  <AppProvider>
    <>
      <Router />
      <NotificationToast />
    </>
  </AppProvider>
);

export default App;
