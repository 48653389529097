import type { PatchedRequestInit } from 'graphql-request/dist/types';
import { GraphQLClient } from 'graphql-request';

import { ApiUrls, getApiUrls } from '@dvag/dfs-constant-config';

import getEnvConfig from 'api/envConfig';
import { getToken, getContext } from '@dvag/dfs-orchestrator-client';
import { AxiosRequestConfig } from 'axios';

const getRequestConfig = (config?: AxiosRequestConfig): AxiosRequestConfig => {
  const { user } = getContext();
  const accessToken = getToken();

  const { parentUserId } = user;
  const requestConfig = { headers: {}, ...(config || {}) };

  if (requestConfig && requestConfig.headers) {
    requestConfig.headers.Authorization = `Bearer ${accessToken}`;
    if (parentUserId) {
      requestConfig.headers['PARENT-USER-ID'] = parentUserId;
    }
  }
  return requestConfig;
};

export const getGQLClient = (service: keyof ApiUrls = 'accounting') => {
  const url = getApiUrls(getEnvConfig().env).graphQl[service.toString()];
  return new GraphQLClient(url, getRequestConfig() as PatchedRequestInit);
};

export default { getGQLClient };
