import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { getIncome, incomesKey } from '@service/incomes';

import usePersons from './usePersons';

export const useIncomes = () => {
  const { persons } = usePersons();
  const { scenarioId } = useParams();

  const personIds = persons.map((person) => person.id);
  return useQuery(incomesKey(scenarioId), () =>
    getIncome({
      scenarioId,
      personId: personIds,
    }),
  );
};

export default useIncomes;
