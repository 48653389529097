import { getLocale, i18n } from 'locales';
import { formatDisplayDate } from '@utils/date/formatDisplayDate';
import analytics from '@dvag/dfs-accounting-analytics';

const transformValue = (data) => {
  const formattedDate = formatDisplayDate(new Date(data.updatedAt), getLocale());
  return {
    preLabel: i18n.t('hhb.simulation.comboboxUpdatedAtLabel', { date: formattedDate }),
    label: data.name,
    subLabel: analytics.toCurrency(data.surplus?.monthlySurplus || 0, getLocale()),
  };
};

export default transformValue;
