import { AxiosError } from 'axios';
import { i18n } from 'locales';

class NotFoundError extends AxiosError {
  constructor(error: AxiosError) {
    const message = i18n.t('errors.generic.notFound');
    const { code, config, request, response } = error;
    super(message, code, config, request, response);
    this.name = this.constructor.name;
  }
}

export default NotFoundError;
