import { i18n } from 'locales';

export interface GalleryOption {
  title: string;
  image: string;
  content: string;
  dataTestId: string;
}

export const RetirementSlideshowOptions: GalleryOption[] = [
  {
    title: i18n.t('hhb.simulation.moreInformation.retirementModal.newFreedom.title'),
    image: '/assets/media/retirement_1.jpg',
    content: i18n.t('hhb.simulation.moreInformation.retirementModal.newFreedom.content'),
    dataTestId: 'new-freedom',
  },
  {
    title: i18n.t('hhb.simulation.moreInformation.retirementModal.theImportantThingsInLife.title'),
    image: '/assets/media/retirement_2.jpg',
    content: i18n.t(
      'hhb.simulation.moreInformation.retirementModal.theImportantThingsInLife.content',
    ),
    dataTestId: 'the-important-things-in-life',
  },
  {
    title: i18n.t(
      'hhb.simulation.moreInformation.retirementModal.lessTravelTimeMoreFreeTime.title',
    ),
    image: '/assets/media/retirement_3.jpg',
    content: i18n.t(
      'hhb.simulation.moreInformation.retirementModal.lessTravelTimeMoreFreeTime.content',
    ),
    dataTestId: 'less-travel-time-more-free-time',
  },
];

export const ManpowerSlideshowOptions: GalleryOption[] = [
  {
    title: i18n.t('hhb.simulation.moreInformation.manpowerModal.shortTermFailure.title'),
    image: '/assets/media/manpower_1.jpg',
    content: i18n.t('hhb.simulation.moreInformation.manpowerModal.shortTermFailure.content'),
    dataTestId: 'short-term-failure',
  },
  {
    title: i18n.t('hhb.simulation.moreInformation.manpowerModal.mediumTermFailure.title'),
    image: '/assets/media/manpower_2.jpg',
    content: i18n.t('hhb.simulation.moreInformation.manpowerModal.mediumTermFailure.content'),
    dataTestId: 'medium-term-failure',
  },
  {
    title: i18n.t('hhb.simulation.moreInformation.manpowerModal.longTermAndPermanentFailure.title'),
    image: '/assets/media/manpower_3.jpg',
    content: i18n.t(
      'hhb.simulation.moreInformation.manpowerModal.longTermAndPermanentFailure.content',
    ),
    dataTestId: 'long-and-permaent-term-failure',
  },
];
