import React, { FC } from 'react';
import { DxButton } from '@dvag/design-system-react';

import { i18n } from 'locales';
import useNavigation from 'hooks/useNavigation';

type Props = {
  centeredFooter?: boolean;
  handleBack?: () => void;
  handleContinue?: () => void;
  showFormActionLabels?: boolean;
};

const Footer: FC<Props> = (props) => {
  const { handleBack, handleContinue, showFormActionLabels, centeredFooter } = props;
  const { navigateBack } = useNavigation();

  const defaultClassName =
    'md:p-0 md:w-auto md:flex-row w-full flex items-center flex-col-reverse sm:flex-row justify-end pt-4 gap-2 sm:gap-0';
  const centeredClassName =
    'flex justify-center md:p-0 md:w-auto md:flex-row w-full flex items-center flex-col-reverse sm:flex-row justify-end md:justify-start pt-4 gap-2 sm:gap-0';

  const className = centeredFooter ? centeredClassName : defaultClassName;

  return (
    <div className={className} data-testid="footer">
      <DxButton
        stretch
        className="justify-end sm:my-6 sm:w-auto"
        unfocusable
        type="text"
        icon="pfeil-links"
        iconposition="left"
        data-testid="back-button"
        onClick={handleBack || navigateBack}
        label={showFormActionLabels ? i18n.t('general.cancel') : i18n.t('general.back')}
      />
      {!!handleContinue && (
        <DxButton
          stretch
          className="justify-end sm:w-auto sm:pl-6"
          unfocusable
          type="primary-m"
          icon="pfeil-rechts"
          iconposition="right"
          data-testid="continue-button"
          onClick={handleContinue}
          label={showFormActionLabels ? i18n.t('general.save') : i18n.t('general.continue')}
        />
      )}
    </div>
  );
};

Footer.defaultProps = {
  centeredFooter: false,
  handleBack: undefined,
  handleContinue: undefined,
  showFormActionLabels: false,
};

export default Footer;
