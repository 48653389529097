import { QueryKey, QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import {
  onErrorTransactionsComputationsCacheUpdateForExpense,
  onMutateTransactionsComputationsCacheUpdateForExpense,
} from 'utils';
import { initAccountingApi } from 'api';
import { ExpenseTransaction } from 'types/transaction';
import { transactionKey } from '@service/transaction';

interface Props {
  householdId: string;
  invalidateTransactionComputationsArgs?: Parameters<(typeof transactionKey)['computations']>['0'];
}

export type MutationArgType = ExpenseTransaction;

interface UpdateCacheProps {
  queryClient: QueryClient;
  mutationKey: QueryKey;
  id: string | undefined;
  contractId?: number;
  updatedTransaction: ExpenseTransaction;
}

const updateCache = ({
  id,
  contractId,
  queryClient,
  mutationKey,
  updatedTransaction,
}: UpdateCacheProps) => {
  const cache = queryClient.getQueryData<{ data: ExpenseTransaction[] }>(mutationKey)?.data || [];

  let foundFirstMatch = false;
  const updatedCache = cache.map((transaction) => {
    const idMatches = id && transaction.id === id;
    const contractIdMatches = contractId && transaction.contractId === contractId;
    if ((id ? idMatches : contractIdMatches) && !foundFirstMatch) {
      foundFirstMatch = true;
      return updatedTransaction;
    }
    return transaction;
  });
  queryClient.setQueryData(mutationKey, { data: updatedCache });
  return cache;
};

export const useUpdateSplitTransaction = (props: Props) => {
  const { householdId, invalidateTransactionComputationsArgs } = props;
  const { scenarioId } = useParams();
  const mutationKey = transactionKey.expenses({ householdId, scenarioId });

  const queryClient = useQueryClient();

  return useMutation(
    mutationKey,
    async (
      transaction: MutationArgType,
    ): Promise<{ contractId?: number; transaction: ExpenseTransaction }> => {
      const { id, contractId, ...payload } = transaction;
      const { isFixed, personId, transactionTypeId } = payload;

      const url = `/expense/${id || contractId}?isContract=false`;
      const dto = {
        personId,
        householdId,
        isFixed,
        transactionTypeId,
      };
      const response = await initAccountingApi().patch(url, dto, {
        params: {
          scenarioId,
        },
      });

      return {
        contractId,
        transaction: response.data as ExpenseTransaction,
      };
    },
    {
      onMutate: async (updatedTransaction) => {
        await queryClient.cancelQueries(mutationKey);
        const { id, contractId } = updatedTransaction;
        updateCache({ queryClient, mutationKey, id: id as string, contractId, updatedTransaction });
        const { prevTransactionsComputationsCacheArr } =
          onMutateTransactionsComputationsCacheUpdateForExpense({
            queryClient,
            householdId,
            scenarioId,
            updatedTransaction,
          });

        return {
          prevTransactionsComputationsCacheArr,
        };
      },
      onSuccess: ({ contractId, transaction: updatedTransaction }) => {
        if (!invalidateTransactionComputationsArgs) return;
        queryClient.invalidateQueries(
          transactionKey.computations(invalidateTransactionComputationsArgs),
        );

        if (contractId !== updatedTransaction.contractId) {
          const { id } = updatedTransaction;
          updateCache({
            queryClient,
            mutationKey,
            id: id as string,
            contractId,
            updatedTransaction,
          });
        }
      },
      onError: (_error, _, context) => {
        if (context) {
          onErrorTransactionsComputationsCacheUpdateForExpense({
            queryClient,
            prevTransactionsComputationsCacheArr: context.prevTransactionsComputationsCacheArr,
          });
        }
        queryClient.invalidateQueries(mutationKey);
      },
    },
  );
};

export default useUpdateSplitTransaction;
