import React, { FC, ReactNode } from 'react';
import { DxText } from '@dvag/design-system-react';

interface Props {
  title?: string;
  dataTestId: string;
  children?: ReactNode;
  containerClassName?: string;
}

const Header: FC<Props> = (props: Props) => {
  const { title, dataTestId, children, containerClassName } = props;

  return (
    <div
      className={`my-6 flex flex-col gap-2 md:flex-row md:flex-wrap md:items-center md:justify-between ${containerClassName}`}
    >
      <DxText
        type="h3"
        slot="title"
        color="gray-83"
        className="break-all "
        data-testid={dataTestId}
      >
        {title}
      </DxText>
      {children}
    </div>
  );
};
export default Header;

Header.defaultProps = {
  title: '',
  children: undefined,
  containerClassName: '',
};
