export const positionByGrade = {
  '6': 1,
  '5-': 2,
  '5': 3,
  '5+': 4,
  '4-': 5,
  '4': 6,
  '4+': 7,
  '3-': 8,
  '3': 9,
  '3+': 10,
  '2-': 11,
  '2': 12,
  '2+': 13,
  '1-': 14,
  '1': 15,
  '-': null,
};

export const gradeFromPosition = {
  1: '6',
  2: '5-',
  3: '5',
  4: '5+',
  5: '4-',
  6: '4',
  7: '4+',
  8: '3-',
  9: '3',
  10: '3+',
  11: '2-',
  12: '2',
  13: '2+',
  14: '1-',
  15: '1',
  null: '-',
};
