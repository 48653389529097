import { QueryClient } from '@tanstack/react-query';
import { Scenario } from '@store/simulation';
import { TransactionComputation } from '@dvag/dfs-financial-comparison-next';
import { scenariosKey } from '@service/scenario';

const addSurplusToSimulationCache = (
  queryClient: QueryClient,
  householdId: string,
  data: TransactionComputation,
) => {
  const simulations = queryClient.getQueryData<Scenario[]>(scenariosKey.byHousehold(householdId));
  if (!simulations || simulations.length === 0) {
    return;
  }
  const updatedSimulations = simulations.map((simulation) => {
    if (simulation.id === data.id) {
      return {
        ...simulation,
        surplus: data.currentSurplus,
      };
    }
    return simulation;
  });
  queryClient.setQueryData<Scenario[]>(scenariosKey.byHousehold(householdId), updatedSimulations);
};

export default addSurplusToSimulationCache;
