import analytics from '@dvag/dfs-accounting-analytics';
import { i18n, getLocale } from 'locales';

export const edit = 'edit';
export const split = 'split';

export const maxValue = 999999.99;
export const subtitles = {
  value: {
    0: `P1. ${i18n.t('hhb.splitProduct.partialAmount')}`,
    1: `P2. ${i18n.t('hhb.splitProduct.partialAmount')}`,
  },
  category: `${i18n.t('hhb.splitProduct.ffgCategory')}*`,
};

export const SPECIAL_PRODUCTS = ['151', '117'];

export const validationMessage = (maxAmount: number): string =>
  analytics.toCurrency(maxAmount, getLocale());
