import React, { ComponentProps, FC } from 'react';
import { DxText } from '@dvag/design-system-react';

import PersonSelector from '@components/PersonSelector';

interface HeaderWithPersonSelector extends ComponentProps<typeof PersonSelector> {
  title: string;
  householdOptionForHouseholdsWithOnePerson?: boolean;
  buttonComponent?: React.ReactNode;
}

const HeaderWithPersonSelector: FC<HeaderWithPersonSelector> = ({
  title,
  buttonComponent,
  householdOptionLabel,
  householdOptionForHouseholdsWithOnePerson,
}) => (
  <>
    <div
      className="mb-6 md:flex md:flex-wrap md:items-center md:justify-between"
      data-testid="header"
    >
      <DxText
        type="h3"
        slot="title"
        color="gray-83"
        data-testid="page-title"
        className="text-title xl:text-title-xl"
      >
        {title}
      </DxText>
      <PersonSelector
        householdOptionLabel={householdOptionLabel}
        householdOptionForHouseholdsWithOnePerson={householdOptionForHouseholdsWithOnePerson}
      />
    </div>
    {buttonComponent && <div className="-mt-4 mb-6 flex justify-end">{buttonComponent}</div>}
  </>
);

HeaderWithPersonSelector.defaultProps = {
  buttonComponent: undefined,
  householdOptionForHouseholdsWithOnePerson: true,
};

export default HeaderWithPersonSelector;
