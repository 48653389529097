import { TransactionType } from 'types/transaction';

const groupTransactionTypes = (
  displayedTransactionTypes: TransactionType[],
): { [displayName: string]: TransactionType[] } =>
  displayedTransactionTypes.reduce((acc, transactionType) => {
    const {
      shortName,
      displayGroupName: transactionTypeGroupName,
      displayGroupParentId,
    } = transactionType;
    if (displayGroupParentId) {
      if (!acc[transactionTypeGroupName]) {
        acc[transactionTypeGroupName] = [transactionType];
      } else {
        acc[transactionTypeGroupName].push(transactionType);
      }
      return acc;
    }

    if (shortName) {
      if (!acc[shortName]) {
        acc[shortName] = [transactionType];
      } else {
        acc[shortName].push(transactionType);
      }
    }
    return acc;
  }, {});

export default groupTransactionTypes;
