import { useRef, useState, useEffect } from 'react';
/**
 * React's rules prevent accessing refs during the render phase, which
 * can cause errors if the ref isn't initialized.
 *
 * To avoid this, the DOM element is created lazily within `useEffect`, ensuring it’s only
 * accessed after the component mounts. This fix prevents the "Ref values may not be accessed
 * during render" error by ensuring safe initialization before use.
 */

const useLazyInit = <T>(initialValue: () => T): T => {
  const ref = useRef<T | null>(null);
  const [value, setValue] = useState<T>(() => initialValue());

  useEffect(() => {
    if (ref.current === null) {
      const resolvedValue = initialValue();
      ref.current = resolvedValue;
      setValue(resolvedValue);
    }
  }, [initialValue]);

  return value;
};
export default useLazyInit;
