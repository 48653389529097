import React, { FC, ReactNode } from 'react';
import { DxText } from '@dvag/design-system-react';

interface Props {
  children: ReactNode;
  title: string;
}

const PageTitleLayout: FC<Props> = ({ children, title }) => (
  <div
    data-testid="page-layout"
    className="mx-auto max-w-layout px-4 pb-6 pt-4 sm:px-6 md:px-8 lg:px-8 xl:px-10"
  >
    <DxText
      type="h3"
      slot="title"
      data-testid="page-title"
      color="gray-83"
      className="mb-6 block flex-1 text-3xl"
    >
      {title}
    </DxText>
    {children}
  </div>
);

export default PageTitleLayout;
