import { immer } from 'zustand/middleware/immer';
import { NavigatedFrom, NavigationSlice } from './type';

const createNavigationSlice = immer<NavigationSlice>((set) => ({
  navigation: {
    navigatedFrom: 'expensesPage',
    actions: {
      updateNavigatedFrom: (navigatedFrom: NavigatedFrom) =>
        set((draftState) => {
          draftState.navigation.navigatedFrom = navigatedFrom;
        }),
    },
  },
}));

export default createNavigationSlice;
