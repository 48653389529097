import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './en.json';
import de from './de.json';

export type Locale = 'de' | 'en';

export const locales: { [Key in Locale]: Key } = {
  de: 'de',
  en: 'en',
} as const;

export const defaultLocale = locales.de;

export const getLocale = (): Locale => i18n.language as Locale;

const resources = {
  [locales.en]: { translation: en },
  [locales.de]: { translation: de },
};

export const defaultNS = 'translation';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
    resources: (typeof resources)['de'];
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnNull: false,
    resources,
    defaultNS,
    detection: {
      order: ['navigator'],
    },
    fallbackLng: defaultLocale,
    interpolation: {
      escapeValue: false,
    },
    debug: false,
    react: {
      nsMode: 'default',
    },
  });

export default i18n;
