import React, { FC, useState } from 'react';
import { DxButton } from '@dvag/design-system-react';

import { i18n } from 'locales';
import { Person } from '@store/person';

import WishesAndGoalsGalleryModal from './WishesAndGoalsGalleryModal';

interface Props {
  persons: Person[];
}

const WishesAndGoalsGallery: FC<Props> = () => {
  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };

  return (
    <div className="pr-6">
      <DxButton
        type="text"
        onClick={openModal}
        data-testid="wishes-and-goals-button"
        label={i18n.t('wishesAndGoals.button')}
      />
      <WishesAndGoalsGalleryModal setShowModal={setShowModal} showModal={showModal} />
    </div>
  );
};

export default WishesAndGoalsGallery;
