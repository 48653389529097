import { getLocale } from 'locales';

const testNumber = 1234.56;

export const getDecimalSeparator = (decimalNumber: number) =>
  new Intl.NumberFormat(getLocale())
    .formatToParts(decimalNumber)
    .find((part) => part.type === 'decimal') || { value: ',' };

export const decimalSeparator = getDecimalSeparator(testNumber);
export const currencyPlaceholder = `0${decimalSeparator.value}00`;

export const getCurrencyPlaceholder = (precison: number) => {
  const separator = decimalSeparator.value;

  if (String(precison) === '0') return '0';

  const numberOfDecimals = ''.padEnd(precison, '0');

  return `0${separator}${numberOfDecimals}`;
};
