import React, { FC } from 'react';
import HeaderWithPersonSelector from './HeaderWithPersonSelector';

interface Props extends React.ComponentProps<typeof HeaderWithPersonSelector> {
  children: React.ReactNode;
  buttonComponent?: React.ReactNode;
  householdOptionForHouseholdsWithOnePerson?: boolean;
  containerClassname?: string;
  dataTestId?: string;
}

const BasicDataLayout: FC<Props> = ({
  children,
  title,
  buttonComponent,
  containerClassname,
  householdOptionLabel,
  householdOptionForHouseholdsWithOnePerson,
  dataTestId,
}) => (
  <div
    className={`${containerClassname} px-4 py-8 sm:px-6 md:px-8 lg:px-10`}
    data-testid={dataTestId}
  >
    <HeaderWithPersonSelector
      title={title}
      buttonComponent={buttonComponent}
      householdOptionLabel={householdOptionLabel}
      householdOptionForHouseholdsWithOnePerson={householdOptionForHouseholdsWithOnePerson}
    />
    {children}
  </div>
);

BasicDataLayout.defaultProps = {
  buttonComponent: undefined,
  containerClassname: '',
  householdOptionForHouseholdsWithOnePerson: true,
  dataTestId: 'basic-data-layout',
};

export default BasicDataLayout;
