import React from 'react';

type DvagOwnIconType = { className?: string };
const DvagOwnIcon = ({ className }: DvagOwnIconType): JSX.Element => (
  <svg
    className={className}
    data-testid="dvag-own-icon"
    width="24"
    height="24"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM10.8056 11.2663H5.19434V3.87427H10.7228V5.24086H6.81975V6.82486H10.4019V8.08791H6.81975V9.89968H10.8056V11.2663Z"
      fill="#C8AA22"
    />
  </svg>
);
DvagOwnIcon.defaultProps = {
  className: '',
};
export default DvagOwnIcon;
