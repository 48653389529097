import { immer } from 'zustand/middleware/immer';
import { Person, PersonSlice } from './type';

const createPersonSlice = immer<PersonSlice>((set) => ({
  person: {
    actions: {
      setCurrentPerson: (currentPerson: Person['syncId']) =>
        set((draftState) => {
          draftState.person.state.currentPerson = currentPerson;
        }),
    },
    state: {
      currentPerson: '',
    },
  },
}));

export default createPersonSlice;
