import { getContext, addContextUpdateListener } from '@dvag/dfs-orchestrator-client';
import { FeatureDefinition, GrowthBook } from '@growthbook/growthbook-react';

export const featurePermissionMap = {
  NSimulation: 'permission.vpd.app-web-vp-digital.n-simulationen',
  SavingsPlanV2: 'feature.accounting.saving-plan',
} as const;

export type Feature = keyof typeof featurePermissionMap;
export type Permission = (typeof featurePermissionMap)[keyof typeof featurePermissionMap];

type FeatureFlag = {
  gb: GrowthBook<Record<string, unknown>>;
  isOn: (feature: Feature) => boolean;
  init: () => void;
};

const featureFlag: FeatureFlag = {
  gb: new GrowthBook(),
  init: () => {
    const initFeatures = getContext().featureFlags as unknown as Record<
      string,
      FeatureDefinition<unknown>
    >;
    const { parentUserId, id: userId } = getContext().user;
    const payload = {
      features: initFeatures,
      attributes: {
        advisorId: parentUserId ?? userId,
        env: window.dfs.env,
      },
    };
    featureFlag.gb.initSync({
      payload,
    });
    addContextUpdateListener(({ featureFlags }) => {
      const updatedFeatures = featureFlags as unknown as Record<string, FeatureDefinition<unknown>>;
      featureFlag.gb.setPayload({
        ...payload,
        features: updatedFeatures,
      });
    });
  },
  isOn: (feature: Feature) => {
    const permission = featurePermissionMap[feature];
    return featureFlag.gb.isOn(permission);
  },
};

export default featureFlag;
