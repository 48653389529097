import { useQuery } from '@tanstack/react-query';

import { initAccountingApi } from 'api';
import { ExpenseTransaction } from 'types/transaction';
import { useParams } from 'react-router-dom';

interface ResponseType {
  data: ExpenseTransaction[];
  isFetched: boolean;
  error: unknown;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
}
interface Props {
  id: string;
  personId: string;
  householdId: string;
  vertragsnummerMitUnterdepotnummer: string;
  enabled: boolean;
}

const useSplitContracts = ({
  id,
  personId,
  householdId,
  vertragsnummerMitUnterdepotnummer,
  enabled,
}: Props): ResponseType => {
  const queryKey = [`split-contract`, { personId }];
  const { scenarioId } = useParams();
  const url = `/expense/split/${id}`;
  const {
    data: response,
    error,
    isError,
    isFetched,
    isSuccess,
    isFetching,
  } = useQuery(
    queryKey,
    async () =>
      initAccountingApi().get(url, {
        params: {
          scenarioId,
          personId,
          householdId,
          vertragsnummerMitUnterdepotnummer,
        },
      }),
    {
      cacheTime: 0,
      enabled: enabled && !!personId,
    },
  );

  return {
    data: (response?.data as ExpenseTransaction[]) || [],
    isFetched,
    error,
    isError,
    isSuccess,
    isLoading: isFetching,
  };
};

export default useSplitContracts;
