export interface FinancialGrade {
  id: string;
  personId: string | null;
  currentGrade: string | null;
  desiredGrade: string | null;

  personA?: string;
  personB?: string;
  createdAt?: string;
  updatedAt?: string;
}

export enum SliderSection {
  CURRENT_GRADE = 'currentGrade',
  DESIRED_GRADE = 'desiredGrade',
}

type FinancialGradeConfig = { type: string; title: string };

type State = {
  config: FinancialGradeConfig[];
  gradeLabels: number[];
  positionByGrade: (position: string | null) => string;
  gradeFromPosition: (grade: string) => string;
};
export interface FinancialGradeSlice {
  financialGrade: State;
}
