import React, { MouseEvent } from 'react';
import { DxButton } from '@dvag/design-system-react';
import { i18n } from 'locales';

type Props = {
  className: string;
  onClick: (event: MouseEvent<HTMLDxButtonElement>) => void;
};

const SimulationEndButton = ({ className, onClick }: Props) => (
  <>
    <DxButton
      stretch
      unfocusable
      type="text"
      onClick={onClick}
      icon="pfeil-links"
      data-testid="end-simulation-1"
      label={i18n.t('hhb.simulation.end')}
      className={`${className} md:hidden`}
    />
    <DxButton
      unfocusable
      type="text"
      onClick={onClick}
      icon="pfeil-links"
      data-testid="end-simulation-2"
      label={i18n.t('hhb.simulation.end')}
      className={`${className} hidden md:block`}
    />
  </>
);

export default SimulationEndButton;
