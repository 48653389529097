import React, {
  FC,
  useMemo,
  ReactNode,
  Dispatch,
  useContext,
  createContext,
  SetStateAction,
} from 'react';

interface ExpensesContextProvider {
  activeType: string;
  showCustomExpenseType: string;
  setActiveType: Dispatch<SetStateAction<string>>;
  setShowCustomExpenseType: Dispatch<SetStateAction<string>>;
}

interface PropType extends ExpensesContextProvider {
  children: ReactNode;
}

export const defaultExpenseContextValues = {
  activeType: '',
  showCustomExpenseType: '',
  setActiveType: () => null,
  setShowCustomExpenseType: () => null,
};

const ExpensesContext = createContext<ExpensesContextProvider>(defaultExpenseContextValues);

export const useExpensesContext = () => useContext(ExpensesContext);

export const ExpensesContextProvider: FC<PropType> = (props) => {
  const { children, activeType, setActiveType, showCustomExpenseType, setShowCustomExpenseType } =
    props;

  const value = useMemo(
    () => ({
      activeType,
      setActiveType,
      showCustomExpenseType,
      setShowCustomExpenseType,
    }),
    [activeType, setActiveType, setShowCustomExpenseType, showCustomExpenseType],
  );
  return <ExpensesContext.Provider value={value}>{children}</ExpensesContext.Provider>;
};
