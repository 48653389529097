import React, { useEffect, useMemo, useState } from 'react';
import { debounce } from 'debounce';
import { i18n } from 'locales';

import UnsavedFormAlert from './UnsavedFormAlert';
import PendingRequestModal from './PendingRequestModal';

interface Props {
  showPending: boolean;
  showUnsaved: boolean;
  onCancel: (() => void) | undefined;
  onConfirm: (() => void) | undefined;
}

const UnsavedOrPendingPopup = ({
  showPending: currentShowPending,
  showUnsaved: currentShowUnsaved,
  onCancel,
  onConfirm,
}: Props) => {
  const [showUnsaved, setShowUnsaved] = useState(currentShowUnsaved);
  const [showPending, setShowPending] = useState(currentShowPending);
  const DEBOUNCE_TIME = 120;
  const debouncedSetShowUnsaved = useMemo(() => debounce(setShowUnsaved, DEBOUNCE_TIME), []);
  const debouncedSetShowPending = useMemo(() => debounce(setShowPending, DEBOUNCE_TIME), []);

  useEffect(() => {
    debouncedSetShowUnsaved(currentShowUnsaved);
  }, [debouncedSetShowUnsaved, currentShowUnsaved]);

  useEffect(() => {
    debouncedSetShowPending(currentShowPending);
  }, [debouncedSetShowPending, currentShowPending]);

  return (
    <>
      <UnsavedFormAlert
        onCancel={onCancel}
        onConfirm={onConfirm}
        visible={showUnsaved}
        body={i18n.t('warnings.invalidDataWannaLeave')}
      />
      {showPending && <PendingRequestModal onCancel={onCancel} visible={showPending} />}
    </>
  );
};

export default UnsavedOrPendingPopup;
