import { useQuery } from '@tanstack/react-query';

import { initAccountingApi } from 'api';
import { TransactionTypesEnum } from 'utils';
import { TransactionType, IncomeTransactionType } from 'types/transaction';
import { useParams } from 'react-router-dom';
import useStore from '@store';

type TransactionTypes = TransactionType[] & IncomeTransactionType[];

interface UseTransactionType {
  type: TransactionTypesEnum;
  householdId: string;
}

const useTransactionType = ({ type, householdId }: UseTransactionType) => {
  const { scenarioId } = useParams();
  const { setTransactionTypes } = useStore((state) => state.transactionTypes.actions);
  const queryKey = [`transaction-types`, { householdId, type, scenarioId }];
  const url = `v4/transaction-type?householdId=${householdId}&type=${type}`;

  const { data, isError, isSuccess, isLoading } = useQuery(
    queryKey,
    async () =>
      initAccountingApi().get<TransactionTypes>(url, {
        params: {
          scenarioId,
        },
      }),
    {
      select: (responseData) => ({
        data: responseData.data || [],
      }),
      onSuccess: (results) => {
        if (type === TransactionTypesEnum.Hhb) {
          setTransactionTypes(results.data, TransactionTypesEnum.Hhb);
        }
      },
      refetchOnMount: false,
      staleTime: Infinity,
    },
  );

  return {
    isError,
    isSuccess,
    isLoadingTransactionTypes: isLoading,
    transactionTypes: data?.data || [],
  };
};

export default useTransactionType;
