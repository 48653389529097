const flattenObject = (obj, prefix = '') => {
  if (typeof obj === 'object' && !Array.isArray(obj)) {
    return Object.keys(obj).reduce((acc, key) => {
      const prefixedKey = prefix ? `${prefix}.${key}` : key;
      const value = obj[key];
      if (typeof value === 'string') {
        acc[prefixedKey] = value;
      } else {
        Object.assign(acc, flattenObject(value, prefixedKey));
      }
      return acc;
    }, {});
  }

  return false;
};

export default flattenObject;
