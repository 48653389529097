import { queryConfig } from '@lib';
import { getCheckIncome } from '@service/incomes';
import personLoader from './personLoader';

const incomeLoader = async (router) => {
  const { params } = router;
  const fetchPersons = personLoader(queryConfig);
  const { persons } = await fetchPersons(router);
  const personIds = persons.map((person) => person.id);

  const checkIncome = await getCheckIncome({
    personId: personIds,
    scenarioId: params.scenarioId,
  });
  return { checkIncome };
};

export default incomeLoader;
