export enum TransactionTypesEnum {
  Hhb = 'hhb',
  Income = 'income',
  Expense = 'expense',
  Expenses = 'expenses',
  ContractOverview = 'contract-overview',
}

export enum DisplayGroupTypesEnum {
  Schutzengel = 'SCHUTZENGEL',
  Wohnen = 'WOHNEN',
  Lebenundconsum = 'LEBEN_KONSUM',
  Sparen = 'SPAREN',
  Surplus = 'SURPLUS',
}

export enum IncomeDisplayGroupEnum {
  NichtselbststaendigeArbeit = 'NICHTSELBSTSTAENDIGE_ARBEIT',
  SelbststaendigeArbeit = 'SELBSTSTAENDIGE_ARBEIT',
  Kindergeld = 'KINDERGELD',
  WeitereEinkuenfte = 'WEITERE_EINKUENFTE',
}
