export enum HousingPropertyTypes {
  EINFAMILIENHAUS = 'EINFAMILIENHAUS',
  EIGENTUMSWOHNUNG = 'EIGENTUMSWOHNUNG',
  FERIENWOHNUNG = 'FERIENWOHNUNG',
  GESCHAEFTSGEBAEUDE = 'GESCHAEFTSGEBAEUDE',
  UNBEBAUTES_GRUNDSTUECK = 'UNBEBAUTES_GRUNDSTUECK',
  MEHRFAMILIENHAUS = 'MEHRFAMILIENHAUS',
  ZWEIFAMILIENHAUS = 'ZWEIFAMILIENHAUS',
  DOPPELHAUSHAELFTE = 'DOPPELHAUSHAELFTE',
  ETAGENWOHNUNG = 'ETAGENWOHNUNG',
  REIHENHAUS = 'REIHENHAUS',
  EINFAMILIENHAUS_MIT_EINLIEGERWOHNUNG = 'EINFAMILIENHAUS_MIT_EINLIEGERWOHNUNG',
  ERBBAUGRUNDSTUECK = 'ERBBAUGRUNDSTUECK',
  FERTIGHAUS_FIRMA = 'FERTIGHAUS_FIRMA',
}

export enum OwnRealEstateArtEnum {
  UNBEKANNT = 'UNBEKANNT',
  EINFAMILIENHAUS = 'EINFAMILIENHAUS',
  EIGENTUMSWOHNUNG = 'EIGENTUMSWOHNUNG',
  FERIENWOHNUNG = 'FERIENWOHNUNG',
  GESCHAEFTSGEBAEUDE = 'GESCHAEFTSGEBAEUDE',
  UNBEBAUTES_GRUNDSTUECK = 'UNBEBAUTES_GRUNDSTUECK',
  MEHRFAMILIENHAUS = 'MEHRFAMILIENHAUS',
  ZWEIFAMILIENHAUS = 'ZWEIFAMILIENHAUS',
  DOPPELHAUSHAELFTE = 'DOPPELHAUSHAELFTE',
  ETAGENWOHNUNG = 'ETAGENWOHNUNG',
  REIHENHAUS = 'REIHENHAUS',
  EINFAMILIENHAUS_MIT_EINLIEGERWOHNUNG = 'EINFAMILIENHAUS_MIT_EINLIEGERWOHNUNG',
  ERBBAUGRUNDSTUECK = 'ERBBAUGRUNDSTUECK',
  FERTIGHAUS_FIRMA = 'FERTIGHAUS_FIRMA',
}

export enum OwnRealEstateNutzungEnum {
  UNBEKANNT = 'UNBEKANNT',
  EIGENGENUTZT_VERMIETET = 'EIGENGENUTZT_VERMIETET',
  EIGENGENUTZT = 'EIGENGENUTZT',
  VERMIETET = 'VERMIETET',
  FREMDGENUTZT = 'FREMDGENUTZT',
}

export interface OwnRealEstateForm {
  name: string;
  art: OwnRealEstateArtEnum | null;
  nutzung: OwnRealEstateNutzungEnum | null;
  verkehrswert: number | null;
  hasOwnRealEstate?: boolean;
}

export interface OwnRealEstateType extends OwnRealEstateForm {
  id: number;
  personId: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface RentRealEstateFormType {
  wohnflaeche: number | null;
  hasRentRealEstate?: boolean;
}

export interface RentRealEstateType extends RentRealEstateFormType {
  id: number;
  personId: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface HousingInformation {
  id?: string;
  householdId?: string;
  personId: string | null;
  capital: number | null;
  price: number | null;
  siteExists: boolean | null;
  buyRealEstate?: boolean;
  whatToBuy: string | null;
  whenToBuy: string | null;
  createdAt?: string;
  updatedAt?: string;
  hasBuyRealEstate?: boolean;
}

export enum HousingExpenseTypes {
  rent = 'MIETE',
  own = 'BESITZ',
}
