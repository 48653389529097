import { useParams } from 'react-router-dom';
import { QueryClient, QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';

import { initAccountingApi } from 'api';
import { Transaction } from 'types/transaction';
import { transactionKey } from '@service/transaction';

interface Props {
  isContract?: boolean;
  invalidateTransactionComputationsArgs?: Parameters<(typeof transactionKey)['computations']>['0'];
}

const updateTransactionListCache = ({
  queryClient,
  mutationKey,
  newTransaction,
}: {
  queryClient: QueryClient;
  mutationKey: QueryKey;
  newTransaction: Transaction;
}) => {
  const previousDataQuery =
    queryClient.getQueryData<{ data: Transaction[] }>(mutationKey)?.data || [];
  queryClient.setQueryData(mutationKey, { data: [...previousDataQuery, newTransaction] });
};

const useCreateExpenseTransaction = ({
  isContract,
  invalidateTransactionComputationsArgs,
}: Props) => {
  const { scenarioId, householdId } = useParams();
  const queryClient = useQueryClient();

  const mutationKey = transactionKey.expenses({ householdId, scenarioId });

  const url = '/expense';
  const queryFn = async (payload) =>
    initAccountingApi().post<Transaction>(url, payload, {
      params: {
        scenarioId,
        isContract: isContract || false,
      },
    });
  const {
    data: res,
    mutateAsync,
    isLoading,
    isError,
    isSuccess,
  } = useMutation(queryFn, {
    mutationKey,
    onSuccess: (response) => {
      updateTransactionListCache({
        queryClient,
        mutationKey,
        newTransaction: response.data,
      });
      if (invalidateTransactionComputationsArgs) {
        queryClient.invalidateQueries(
          transactionKey.computations(invalidateTransactionComputationsArgs),
        );
      }
    },
    onSettled: () => queryClient.invalidateQueries(mutationKey),
  });

  return {
    isError,
    isLoading,
    isSuccess,
    createTransaction: mutateAsync,
    isCreateTransactionError: isError,
    createdTransaction: (res ? res.data : {}) as Transaction,
  };
};

export default useCreateExpenseTransaction;
