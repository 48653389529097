import { ExpenseTransaction } from 'types/transaction';

const filterTransactionsByComputable = ({
  isHouseholdSelected,
  transactions,
}: {
  isHouseholdSelected?: boolean;
  transactions: ExpenseTransaction[];
}) => {
  if (!isHouseholdSelected) return transactions;
  return transactions.filter((transaction) => {
    if (!transaction.meta?.computable) return transaction;

    return transaction.meta.computable === true;
  });
};
export default filterTransactionsByComputable;
