import { useParams } from 'react-router-dom';
import { ApiResponse } from '@dvag/dfs-api-client/interface';

import { Transaction } from 'types/transaction';
import { useTransactions } from '../useTransactions';

interface ResponseType {
  type?: string;
  isError: boolean;
  seedTransactions: Transaction[];
  isSuccessSeedTransactions?: boolean;
  isLoadingSeedTransactions: boolean;
}

interface Props {
  householdId: string;
  enabled?: boolean;
}

const select = (response: ApiResponse<Transaction[]>) => {
  if (!response.data) {
    return { data: [] };
  }

  return {
    data: response.data
      .filter((transaction) => !!transaction.seedValue)
      .map(({ seedValue, ...transaction }) => ({ ...transaction, ...seedValue })),
  };
};

export const useSeedTransactions = ({ householdId, enabled }: Props): ResponseType => {
  const { scenarioId } = useParams();

  const {
    isError,
    isSuccess: isSuccessSeedTransactions,
    transactions: seedTransactions,
    isLoadingTransactions: isLoadingSeedTransactions,
  } = useTransactions({
    params: { select, enabled: !!scenarioId && enabled },
    householdId,
  });

  return {
    isError,
    seedTransactions,
    isSuccessSeedTransactions,
    isLoadingSeedTransactions,
  };
};

export default useSeedTransactions;
