import { Transaction, TransactionType } from 'types/transaction';
import filterTransactionsById from './filterTransactionsById';

const filterTransactionTypesByState = (
  transactionTypes: TransactionType[],
  transactions: Transaction[],
  visibleByDefaultTransactionTypes: TransactionType[],
  temporaryTransactionTypes: TransactionType[],
) =>
  transactionTypes.filter((transactionType) => {
    const transactionTypeTransactions = filterTransactionsById(transactions, transactionType);
    const isTransactionTypeOutsideDropdown = !!visibleByDefaultTransactionTypes.find(
      (ttOutsideDropdown) => ttOutsideDropdown.id === transactionType.id,
    );
    const isTransactionTypeInTemporaryState = !!temporaryTransactionTypes.find(
      (ttTemporary) => ttTemporary.id === transactionType.id,
    );
    return (
      transactionTypeTransactions.length &&
      !isTransactionTypeOutsideDropdown &&
      !isTransactionTypeInTemporaryState
    );
  });

export default filterTransactionTypesByState;
