function getDateDetails({
  date,
  yearOffset,
  dayOffset,
  isZeroBasedMonth,
}: {
  date: Date;
  yearOffset?: number;
  dayOffset?: number;
  isZeroBasedMonth?: boolean;
}) {
  const pastDate = new Date(date);

  if (typeof yearOffset === 'number') {
    pastDate.setFullYear(pastDate.getFullYear() + yearOffset);
  }

  if (typeof dayOffset === 'number') {
    pastDate.setDate(pastDate.getDate() + dayOffset);
  }

  const monthOffset = isZeroBasedMonth ? 0 : 1;

  const year = pastDate.getFullYear();
  const month = pastDate.getMonth() + monthOffset;
  const day = pastDate.getDate();

  return { year, month, day };
}

export default getDateDetails;
