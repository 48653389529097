import { i18n } from 'locales';

type ConfigPropType = {
  simulationMode: boolean | undefined;
};
type ConfigReturnType = {
  id: string;
  name: string;
  label: string;
  title: string;
  subtitle: string;
};

export const HHB_TABS = {
  vertragsuebersicht: 'vertragsuebersicht',
  ausgaben: 'ausgaben',
};

const expensesTab = ({ simulationMode }) => ({
  id: HHB_TABS.ausgaben,
  name: HHB_TABS.ausgaben,
  label: i18n.t(simulationMode ? 'hhb.simulation.simulationTitle' : 'hhb.expenses.title'),
  title: i18n.t(simulationMode ? 'hhb.simulation.simulationTitle' : 'hhb.tabs.expenses'),
  subtitle: i18n.t(simulationMode ? 'hhb.simulation.simulationSubtitle' : 'hhb.expenses.subtitle'),
});

const config = ({ simulationMode }: ConfigPropType): ConfigReturnType[] =>
  simulationMode
    ? [expensesTab({ simulationMode })]
    : [
        expensesTab({ simulationMode }),
        {
          id: HHB_TABS.vertragsuebersicht,
          name: HHB_TABS.vertragsuebersicht,
          label: i18n.t('hhb.tabs.contractOverview'),
          title: i18n.t('hhb.contractOverview.title'),
          subtitle: i18n.t('hhb.contractOverview.subtitle'),
        },
      ];

export default config;
