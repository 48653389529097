import { immer } from 'zustand/middleware/immer';
import { Scenario, SimulationSlice } from './type';

const createSimulationSlice = immer<SimulationSlice>((set) => ({
  simulation: {
    actions: {
      update: (current: Scenario, action: 'delete' | 'update' | 'create' | 'reset') =>
        set((draftState) => {
          draftState.simulation.state.current = current;
          draftState.simulation.state.action = action;
        }),
      clear: () =>
        set((draftState) => {
          draftState.simulation.state.current = undefined;
          draftState.simulation.state.action = undefined;
        }),
      setCategories: (scenarios: Scenario[]) =>
        set((draftState) => {
          const categories = [...new Set(scenarios.map((scenario) => scenario.type))];
          draftState.simulation.state.categories = categories;
        }),
    },
    state: {
      action: undefined,
      current: undefined,
      categories: [],
    },
  },
}));

export default createSimulationSlice;
