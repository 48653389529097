import { initAccountingApi } from 'api';
import { ExpenseTransaction, TransactionComputation } from 'types/transaction';

export const transactionKey = {
  computations: ({
    householdId = '',
    personId = '',
    scenarioId = '',
  }: {
    householdId: string;
    personId?: string;
    scenarioId?: string;
  }) => ['transaction-computations', { householdId, personId, scenarioId }],
  expenses: ({ householdId, scenarioId }: { householdId: string; scenarioId?: string }) => [
    'expense',
    { householdId, scenarioId },
  ],
};

export async function updateTransaction({
  transaction,
  syncType,
  defaultPersonId,
  isContract,
  scenarioId,
  householdId,
}: {
  transaction: ExpenseTransaction;
  householdId: string;
  syncType?: string;
  defaultPersonId: string;
  isContract: boolean;
  scenarioId?: string;
}) {
  const { id, contractId, scenarioContractId, ...payload } = transaction;
  const { amountAfterTaxes, personId, occurrences, interval, isFixed, transactionTypeId } = payload;
  const dto = {
    personId,
    interval,
    householdId,
    occurrences,
    amountAfterTaxes,
    isFixed,
    transactionTypeId,
  };
  const url = `/expense/${id ?? scenarioContractId ?? contractId}`;
  return initAccountingApi().patch<ExpenseTransaction>(url, dto, {
    params: {
      scenarioId,
      personId: defaultPersonId,
      syncType,
      isContract: isContract || Boolean(contractId),
      ...(contractId && { contractId }),
    },
  });
}

export async function deleteTransaction({
  id,
  contractId,
  isContract,
  scenarioId,
  householdId,
  personId,
}: {
  id: ExpenseTransaction['id'];
  contractId: ExpenseTransaction['contractId'];
  householdId: string;
  personId: string;
  isContract: boolean;
  scenarioId?: string;
}) {
  return initAccountingApi().delete(`/expense/${id}`, {
    params: {
      scenarioId,
      householdId,
      personId,
      isContract,
      ...(contractId && { contractId }),
    },
  });
}

export async function findTransactionComputations({ householdId, personId, scenarioId }) {
  return initAccountingApi()
    .get<TransactionComputation>('v4/transaction/compute', {
      params: { householdId, personId: personId || undefined, scenarioId: scenarioId || undefined },
    })
    .then(({ data }) => ({ ...data, id: scenarioId }));
}
