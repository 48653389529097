import { useCallback, useEffect, useMemo, useState } from 'react';

import { removeMessageListener, addMessageListener } from '@dvag/dfs-orchestrator-client';
import {
  setMicroappIsDirty,
  navigate as orchestratorNavigate,
} from '@dvag/dfs-orchestrator-client/messengers';
import useObservableList from './useObservableList';

export type Continuation = { onConfirm: () => void; onCancel: () => void } | undefined;
export type ContinuationRequest = () => void;

const useContinuationStore = () => {
  const [continuation, setContinuation] = useState<Continuation>(undefined);
  const [shouldUnregister, setShouldUnregister] = useState(false);
  const {
    register: getCanContinueHandler,
    observableList: canContinueList,
    unregisterAll: unregisterAllCanContinueHandlers,
  } = useObservableList<boolean>();

  const {
    register: getIsSubmittingHandler,
    observableList: isSubmittingList,
    unregisterAll: unregisterAllSubmittingHandlers,
  } = useObservableList<boolean>();

  const onIsSubmitting = useMemo(() => getIsSubmittingHandler(), [getIsSubmittingHandler]);

  const canContinue = canContinueList.every((item) => item);
  const [continuationRequestList, setContinuationRequestList] = useState<ContinuationRequest[]>([]);

  const onContinuationRequest = useCallback((continuationRequestCallback: ContinuationRequest) => {
    setContinuationRequestList((previous) => [...previous, continuationRequestCallback]);
  }, []);

  const getContinuationHandler = useCallback(
    (continuationCallback: () => void) => () => {
      setContinuation({
        onConfirm: () => {
          setContinuation(undefined);
          setMicroappIsDirty(false);
          continuationCallback();
        },
        onCancel: () => {
          setContinuation(undefined);
        },
      });
    },
    [],
  );

  useEffect(() => {
    setMicroappIsDirty(!canContinue);
    if (continuation !== undefined) {
      if (canContinue) continuation.onConfirm();
      else continuationRequestList.forEach((request) => request());
    }
  }, [canContinue, continuation, continuationRequestList]);

  useEffect(() => {
    const customMsgId = addMessageListener('navigationRequest', (data) => {
      const requestPayload = (data as { payload: Record<string, string> }).payload;
      const { url, nextUrl } = requestPayload;
      getContinuationHandler(() => orchestratorNavigate(url, nextUrl))();
    });
    return () => {
      removeMessageListener(customMsgId);
    };
  }, [getContinuationHandler]);

  return {
    getIsSubmittingHandler,
    onIsSubmitting,
    isSubmittingList,
    continuation,
    getContinuationHandler,
    getCanContinueHandler,
    onContinuationRequest,
    canContinueList,
    shouldUnregister,
    setShouldUnregister,
    unregisterAllHandlers: useCallback(() => {
      unregisterAllSubmittingHandlers();
      unregisterAllCanContinueHandlers();
    }, [unregisterAllCanContinueHandlers, unregisterAllSubmittingHandlers]),
  };
};

export default useContinuationStore;
