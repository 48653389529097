import useStore from '@store';
import useNavigation from 'hooks/useNavigation';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

const useNavigateFromContract = () => {
  const params = useParams();
  const { navigateTo } = useNavigation();
  const navigatedFrom = useStore((state) => state.navigation.navigatedFrom);
  const { updateNavigatedFrom } = useStore((state) => state.navigation.actions);

  const navigateFromContract = useCallback(
    () =>
      navigatedFrom === 'expensesPage'
        ? navigateTo('analysis.expenses', params)
        : navigateTo('analysis.contractOverview', params),
    [navigateTo, navigatedFrom, params],
  );

  return {
    navigatedFrom,
    updateNavigatedFrom,
    navigateFromContract,
  };
};

export default useNavigateFromContract;
