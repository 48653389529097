import React, { Component, ReactNode } from 'react';

import { HttpStatusCodeEnum } from 'config';

import { Error403, ErrorCrashedApp } from 'screens/error';
import { getAppInsights } from '@lib';

interface Props {
  children: ReactNode;
}

interface State {
  is403Error: boolean;
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { is403Error: false, hasError: false };
  }

  static getDerivedStateFromError(error: { response: { status: number } }) {
    if (error.response?.status === HttpStatusCodeEnum.FORBIDDEN) {
      return { is403Error: true };
    }
    return { hasError: true };
  }

  componentDidCatch(error) {
    const { appInsights } = getAppInsights();
    appInsights.trackException({
      exception: error,
      severityLevel: 3,
    });
  }

  render() {
    const { is403Error, hasError } = this.state;
    const { children } = this.props;

    if (is403Error) {
      return <Error403 />;
    }

    if (hasError) {
      return <ErrorCrashedApp />;
    }

    return children;
  }
}

export default ErrorBoundary;
