import React from 'react';
import { DxNotificationToast, DxText } from '@dvag/design-system-react';
import useStore from '@store';

interface Props {
  icon?: string;
  iconColor?: string;
}

const NotificationToast = ({ icon, iconColor }: Props) => {
  const { type, title, message, statusType } = useStore((state) => state.feedback);
  const { reset } = useStore((state) => state.feedback.actions);

  return (
    <DxNotificationToast
      closeafter={5}
      onCloseNotification={reset}
      icon={icon}
      iconcolor={iconColor}
      data-testid="accounting-notification-toast"
      visible={type === 'toast'}
      titlestring={title}
      type={statusType}
    >
      <DxText type="auto" data-testid="accounting-notification-toast-message">
        {message}
      </DxText>
    </DxNotificationToast>
  );
};

NotificationToast.defaultProps = {
  icon: 'error',
  iconColor: 'error',
};

export default NotificationToast;
