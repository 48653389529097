import { useParams } from 'react-router-dom';
import { QueryClient, QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';

import { ExpenseTransaction, Transaction } from 'types/transaction';
import { deleteTransaction, transactionKey } from '@service/transaction';

interface Props {
  householdId: string;
  invalidateTransactionComputationsArgs?: Parameters<(typeof transactionKey)['computations']>['0'];
}

type IdType = string | number | undefined;

interface MutationArgType {
  id: string | number | undefined;
  personId: string;
  isContract?: boolean;
  contractId: number | undefined;
}

interface ContextType {
  previousDataQuery: Transaction[];
}
export const onSuccess = (
  queryClient: QueryClient,
  invalidateTransactionComputationsArgs?: {
    householdId: string;
    scenarioId?: string;
    personId?: string;
  },
) => {
  if (invalidateTransactionComputationsArgs) {
    queryClient.invalidateQueries(
      transactionKey.computations(invalidateTransactionComputationsArgs),
    );
  }
};

export const onError = (context: ContextType, mutationKey: QueryKey, queryClient: QueryClient) => {
  const { previousDataQuery } = context;
  queryClient.setQueryData(mutationKey, { data: previousDataQuery });
};

export const getSimulationUpdatedData = (cacheData: Transaction[], id: IdType) =>
  cacheData.map((item) => {
    if (item.id === id) {
      if (item.seedValue) {
        return { ...item, deletedAt: new Date().toISOString() };
      }
      return null;
    }
    return item;
  });
export const onMutate = async (
  variables: MutationArgType,
  scenarioId: string | undefined,
  queryClient: QueryClient,
  mutationKey: QueryKey,
) => {
  const { id } = variables;
  await queryClient.cancelQueries(mutationKey);
  const previousDataQuery =
    queryClient.getQueryData<{ data: Transaction[] }>(mutationKey)?.data || [];

  const simulationUpdatedData = getSimulationUpdatedData(previousDataQuery, id);

  const newData = scenarioId
    ? simulationUpdatedData.filter((item) => !!item)
    : previousDataQuery.filter((item) => item.id !== id);
  queryClient.setQueryData(mutationKey, { data: newData });
  return { previousDataQuery };
};

export const useDeleteExpenseTransaction = ({
  householdId,
  invalidateTransactionComputationsArgs,
}: Props) => {
  const { scenarioId } = useParams();
  const queryClient = useQueryClient();

  const mutationKey = transactionKey.expenses({ householdId, scenarioId });

  return useMutation(
    (props: {
      id: ExpenseTransaction['id'];
      contractId: ExpenseTransaction['contractId'];
      personId: string;
      isContract: boolean;
    }) => deleteTransaction({ ...props, householdId, scenarioId }),
    {
      mutationKey,
      onMutate: (variables) => onMutate(variables, scenarioId, queryClient, mutationKey),
      onSuccess: () => {
        onSuccess(queryClient, invalidateTransactionComputationsArgs);
        queryClient.invalidateQueries(mutationKey);
      },
      onError: (_error, _vars, context) =>
        onError(context as ContextType, mutationKey, queryClient),
    },
  );
};

export default useDeleteExpenseTransaction;
