import { DxCombobox } from '@dvag/design-system-react';
import { Scenario } from '@store/simulation';

import React, { useLayoutEffect, useRef } from 'react';
import { i18n } from 'locales';
import { DxComboboxCustomEvent } from '@dvag/design-system/loader';
import transformValue from './transformValue';

type Props = {
  className: string;
  simulation: Scenario;
  simulations: Scenario[];
  testId: string;
  onSelectSearchOption: (simulationId: string) => void;
};
export const filterSimulations = (searchValue: string, simulations: Scenario[]) => {
  if (!searchValue) {
    return simulations;
  }
  const filteredOptions = simulations.filter((option) =>
    option.name.toLowerCase().includes(searchValue.toLowerCase()),
  );
  return filteredOptions.length > 0 ? filteredOptions : [];
};

const SimulationPicker = ({
  className,
  simulation,
  simulations,
  testId,
  onSelectSearchOption,
}: Props) => {
  const ref = useRef<HTMLDxComboboxElement | null>(null);
  const searchRef = useRef(simulation.name);

  const handleSelectSearchOption = (e: DxComboboxCustomEvent<Scenario>) => {
    const selectedSimulation = e.detail;
    const newSimulationSelected = selectedSimulation && selectedSimulation.id !== simulation.id;
    if (newSimulationSelected) {
      onSelectSearchOption(selectedSimulation.id);
    }
  };

  useLayoutEffect(() => {
    const onSearch = (e) => {
      const searchController = e.detail;
      const searchValue = searchController.value;
      searchRef.current = searchValue;
      if (ref.current) ref.current.setAttribute('value', searchValue);

      if (searchValue === simulation.name) {
        const scenariosResult = filterSimulations('', simulations);
        searchController.provideValues([{ options: scenariosResult }]);
        return;
      }

      const scenariosResult = filterSimulations(searchValue, simulations);
      searchController.provideValues([{ options: scenariosResult }]);
    };

    const element = document.querySelector(`#${testId}`);

    if (element) element.addEventListener('search', onSearch);

    return () => {
      if (element) element.removeEventListener('search', onSearch);
    };
  }, [testId, simulations, simulation.name]);

  const handleOnBlur = () => {
    if (!searchRef.current) {
      searchRef.current = simulation.name;
      if (ref.current) {
        ref.current.setAttribute('value', simulation.name);
      }
    }
  };

  return (
    <DxCombobox
      onBlur={handleOnBlur}
      ref={ref}
      id={testId}
      value={simulation.name}
      usagemode="new"
      data-testid={testId}
      transformvalue={transformValue}
      size="s"
      className={className}
      onSelectSearchOption={handleSelectSearchOption}
      emptysearchtext={i18n.t('hhb.simulation.noSearchResultsFoundForInput')}
    />
  );
};

export default SimulationPicker;
