import { i18n } from 'locales';

const getCadenceString = (
  interval: number | undefined,
  occurences: number | null,
): string | undefined => {
  switch (`${occurences}-${interval}`) {
    case '0-0':
      return i18n.t('income.paymentFrequencies.noFrequency');
    case '1-1':
      return i18n.t('income.paymentFrequencies.yearly');
    case '2-1':
      return i18n.t('income.paymentFrequencies.halfYearly');
    case '12-1':
      return i18n.t('income.paymentFrequencies.monthly');
    case '4-1':
      return i18n.t('income.paymentFrequencies.quarterly');
    case '24-1':
      return i18n.t('income.paymentFrequencies.biMonthly');
    case '0-1':
      return i18n.t('income.paymentFrequencies.oneTime');
    case '1-3':
      return i18n.t('income.paymentFrequencies.everyThreeYears');
    default:
      return interval?.toString();
  }
};

export default getCadenceString;
