import React, { FC } from 'react';
import { i18n } from 'locales';
import { Scenario, SimulationType } from '@store/simulation';
import OverflowMenu from '@components/SimulationController/OverflowMenu';
import { DeleteAlert } from 'components';
import useStore from '@store';
import { selectSimulationAction, selectSimulationActions } from '@store/simulation/selector';
import { useParams } from 'react-router-dom';
import { useDeleteScenario, usePersons, useScenarios } from '@hooks';
import { useCreateScenario } from '@hooks/useCreateScenario';
import { useNavigation } from 'hooks';
import filterScenariosByType from '@utils/other/filterScenariosByType';
import { Gallery } from '@components/Gallery';
import {
  ManpowerSlideshowOptions,
  RetirementSlideshowOptions,
} from '@components/Gallery/config/galleryOptions';
import findPersonByPersonIdInScenario from '@utils/other/findPersonByPersonIdInScenario';
import { WishesAndGoalsGallery } from '@components/WishesAndGoalsGallery';
import ResetSimulationAlert from './ResetSimulationAlert';

interface Props {
  scenario: Scenario;
}

const SimulationController: FC<Props> = (props) => {
  const { scenario } = props;
  const { householdId } = useParams();
  const { persons } = usePersons();

  const { navigateTo } = useNavigation();
  const { update, clear } = useStore(selectSimulationActions);
  const action = useStore(selectSimulationAction);

  const { data: simulations } = useScenarios();

  const { mutate: deleteScenario } = useDeleteScenario({ householdId });
  const { mutateAsync: createScenario } = useCreateScenario({
    householdId,
    person: findPersonByPersonIdInScenario(scenario, persons),
    type: scenario?.type,
  });
  const createAndNavigateToNewSimulation = async () => {
    const response = await createScenario();
    update(response.data, 'create');
    const scenarioId = response.data.id;
    if (scenarioId) {
      navigateTo('simulation.expenses', { scenarioId, householdId }, { replace: true });
    }
  };

  const handleDeleteScenario = async () => {
    if (!scenario) return;
    const filteredScenariosByType = filterScenariosByType(simulations || [], scenario.type);

    clear();
    deleteScenario({ id: scenario.id, personId: scenario.personId });
    const remainingSimulations = filteredScenariosByType.filter((sc) => sc.id !== scenario.id);
    if (remainingSimulations.length > 0) {
      navigateTo(
        'simulation.expenses',
        {
          scenarioId: remainingSimulations[0].id,
          householdId,
        },
        { replace: true },
      );
    } else {
      createAndNavigateToNewSimulation();
    }
  };

  return (
    <div data-testid="simulation-controller-wrapper" className="flex justify-between pb-6 md:pb-0">
      {scenario?.type === SimulationType.MANPOWER && <Gallery options={ManpowerSlideshowOptions} />}
      {scenario?.type === SimulationType.RETIREMENT && (
        <Gallery options={RetirementSlideshowOptions} />
      )}
      {scenario?.type === SimulationType.FUTURE_HOUSEHOLD_BUDGET && (
        <WishesAndGoalsGallery persons={persons} />
      )}
      <div>
        <OverflowMenu simulation={scenario} startSimulation={createAndNavigateToNewSimulation} />
        <DeleteAlert
          title={i18n.t('dashboard.scenariosCard.deleteScenarioAlert.title')}
          contentText={i18n.t('dashboard.scenariosCard.deleteScenarioAlert.message')}
          toggleDeleteModal={clear}
          handleDelete={handleDeleteScenario}
          visible={action === 'delete'}
        />
        <ResetSimulationAlert scenario={scenario} />
      </div>
    </div>
  );
};

export default SimulationController;
