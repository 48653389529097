export interface Card {
  label: string;
  imageSrc: string;
  description: string;
  handleStartButtonClick: () => void;
  handleInfoButtonClick?: () => void;
}

export enum SimulationType {
  FUTURE_HOUSEHOLD_BUDGET = 'FUTURE_HOUSEHOLD_BUDGET',
  RETIREMENT = 'RETIREMENT',
  MANPOWER = 'MANPOWER',
}
export enum SimulationName {
  FUTURE_HOUSEHOLD_BUDGET = 'Simulation',
  RETIREMENT = 'Ruhestand',
  MANPOWER = 'Arbeitskraft',
}
export interface Scenario {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  name: string;
  type: SimulationType;
  personId: string;
  showToggle: boolean;
  surplus?: {
    monthlySurplus: number;
    yearlySurplus: number;
  };
  order?: number;
}

type State = {
  current?: Scenario;
  action?: 'delete' | 'update' | 'create' | 'reset';
  categories: string[];
};

export type Action = {
  actions: {
    update: (scenario: Scenario, action: 'delete' | 'update' | 'create' | 'reset') => void;
    clear: () => void;
    setCategories: (scenarios: Scenario[]) => void;
  };
};

export type SimulationSlice = {
  simulation: { state: State } & Action;
};
