import React, {
  FC,
  useMemo,
  ReactNode,
  Dispatch,
  useContext,
  createContext,
  SetStateAction,
} from 'react';
import { DisplayGroupTypesEnum, IncomeDisplayGroupEnum } from 'utils';

interface TransactionTypeContentContextProvider {
  showDetails: boolean;
  showTransactionDetails: string;
  setShowDetails: Dispatch<SetStateAction<boolean>>;
  setShowTransactionDetails: Dispatch<SetStateAction<string>>;
  displayGroupKey: DisplayGroupTypesEnum | IncomeDisplayGroupEnum | undefined;
}

interface PropType extends TransactionTypeContentContextProvider {
  children: ReactNode;
}

export const defaultTransactionTypeContextValues = {
  showDetails: false,
  setShowDetails: () => null,
  showTransactionDetails: '',
  setShowTransactionDetails: () => null,
  displayGroupKey: undefined,
};

const TransactionTypeContentContext = createContext<TransactionTypeContentContextProvider>(
  defaultTransactionTypeContextValues,
);

export const useTransactionTypeContentContext = () => useContext(TransactionTypeContentContext);

export const TransactionTypeContentContextProvider: FC<PropType> = (props) => {
  const {
    children,
    showDetails,
    setShowDetails,
    showTransactionDetails,
    setShowTransactionDetails,
    displayGroupKey,
  } = props;

  const value = useMemo(
    () => ({
      showDetails,
      setShowDetails,
      showTransactionDetails,
      setShowTransactionDetails,
      displayGroupKey,
    }),
    [
      showDetails,
      setShowDetails,
      showTransactionDetails,
      setShowTransactionDetails,
      displayGroupKey,
    ],
  );
  return (
    <TransactionTypeContentContext.Provider value={value}>
      {children}
    </TransactionTypeContentContext.Provider>
  );
};
