import React from 'react';
import featureFlag, { Feature } from '@service/featureFlag';

type Action = 'see';

type Props = {
  I: Action;
  children: React.ReactNode;
  fallback?: React.ReactNode;
  this: Feature;
};

const Can: React.FC<Props> = ({ children, this: feature, fallback }) => {
  const Fallback = fallback;

  const hasPermission = featureFlag.isOn(feature);

  if (hasPermission) return children as React.ReactElement;

  return (Fallback || undefined) as React.ReactElement;
};

Can.defaultProps = {
  fallback: undefined,
};

export default Can;
