import React from 'react';
import { DxAnnouncementBar, DxText } from '@dvag/design-system-react';

import { i18n } from 'locales';

type DxAnnouncementBarProps = React.ComponentProps<typeof DxAnnouncementBar>;

interface Props extends Omit<DxAnnouncementBarProps, 'icon' | 'iconcolor' | 'color'> {
  'data-testid'?: string;
}

const AnnouncementBar: React.FC<Props> = (props) => {
  const { 'data-testid': dataTestId, ...restProps } = props;

  return (
    <div className="pb-6">
      <DxAnnouncementBar
        color="blue-10"
        icon="information"
        iconcolor="gray-83"
        data-testid={dataTestId}
        {...restProps}
      >
        <DxText type="ps" color="gray-83" data-testid="income-alert-text">
          {i18n.t('income.simulationAnnouncement')}
        </DxText>
      </DxAnnouncementBar>
    </div>
  );
};

AnnouncementBar.defaultProps = {
  'data-testid': 'announcement-bar',
};

export default AnnouncementBar;
