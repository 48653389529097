import { useParams } from 'react-router-dom';
import { ApiResponse } from '@dvag/dfs-api-client/interface';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { initAccountingApi } from 'api';

import { Transaction } from 'types/transaction';
import { transactionKey } from '@service/transaction';

import useDeleteSplitContract from '../useDeleteSplitContract';
import { defaultSelect, handleConflict } from './handleConflict';

interface ResponseType {
  type?: string;
  error?: unknown;
  isError: boolean;
  isSuccess?: boolean;
  isFetching?: boolean;
  transactions: Transaction[];
  isLoadingTransactions: boolean;
}

interface Params {
  personId?: string;
  enabled?: boolean;
  select?: (data: ApiResponse<Transaction[]>) => { data: Transaction[] };
}

interface Props {
  householdId: string;
  params?: Params;
}

export const useTransactions = ({ params, householdId }: Props): ResponseType => {
  const {
    personId,
    enabled = true,
    select = defaultSelect as NonNullable<Params['select']>,
  } = params || {};
  const queryClient = useQueryClient();
  const { deleteSplitContract } = useDeleteSplitContract();
  const { scenarioId } = useParams();

  const queryKey = transactionKey.expenses({ householdId, scenarioId });

  const { data, isError, isLoading, isFetching, isSuccess, error } = useQuery(
    queryKey,
    async () =>
      initAccountingApi().get<Transaction[]>('/expense', {
        params: {
          scenarioId,
          householdId,
          personId,
        },
      }),
    {
      select,
      enabled,
      onSuccess: ({ data: response }) =>
        handleConflict({ response, enabled, queryClient, queryKey, deleteSplitContract }),
    },
  );
  return {
    error,
    isError,
    isSuccess,
    isFetching,
    transactions: data?.data || [],
    isLoadingTransactions: isLoading,
  };
};

export default useTransactions;
