import equal from 'fast-deep-equal';
import { useEffect, useRef } from 'react';

export default function useDeepEffect(fn: React.EffectCallback, deps: React.DependencyList) {
  const isFirst = useRef(true);
  const prevDeps = useRef(deps);

  useEffect(() => {
    const isFirstEffect = isFirst.current;
    const isSame = prevDeps.current.every((obj, index) => equal(obj, deps[index]));

    isFirst.current = false;
    prevDeps.current = deps;

    if (isFirstEffect || !isSame) {
      return fn();
    }

    return undefined;
  }, [deps, fn]);
}
