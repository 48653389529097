import { immer } from 'zustand/middleware/immer';
import { FeedbackSlice } from './type';

const createFeedbackSlice = immer<FeedbackSlice>((set) => ({
  feedback: {
    type: null,
    actions: {
      reset: () =>
        set((draftState) => {
          draftState.feedback.type = null;
        }),
      show: (type, title, message, statusType) =>
        set((draftState) => {
          draftState.feedback.statusType = statusType;
          draftState.feedback.type = type;
          draftState.feedback.title = title;
          draftState.feedback.message = message;
        }),
    },
  },
}));

export default createFeedbackSlice;
