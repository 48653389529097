import { transactionKey } from '@service/transaction';
import { ExpenseTransaction, TransactionType } from 'types/transaction';
import { Scenario } from '@store/simulation';
import type { QueryClient } from '@tanstack/react-query';
import { OnColumnDropdownRemove } from 'screens/householdBudget/expenses/types';
import { useDeleteSplitContract, useDeleteTransactionType } from 'hooks';
import groupTransactionsAndTransactionTypes from './groupTransactionsAndTransactionTypes';

type GetDeletrableSplitTransactionsProps = Pick<Props, 'transactionTypesGroup'> & {
  scenarioId?: string;
  transactions: ExpenseTransaction[];
};

const getDeletabeSplitTransactions = ({
  transactionTypesGroup,
  scenarioId,
  transactions,
}: GetDeletrableSplitTransactionsProps) => {
  const groupedTransactionsAndTransactionTypes = groupTransactionsAndTransactionTypes(
    transactionTypesGroup,
    transactions,
  );

  type DeleteSplitTransactionPromises = ExpenseTransaction[];

  const deleteableSplitTransaction: DeleteSplitTransactionPromises =
    groupedTransactionsAndTransactionTypes.reduce((accumulator, group) => {
      if (group.transactions.length) {
        group.transactions.forEach((transaction) => {
          const isSplitTransaction = !!transaction.parentTransactionId && !scenarioId;

          if (isSplitTransaction) {
            accumulator.push(transaction);
          }
        });
      }
      return accumulator;
    }, [] as DeleteSplitTransactionPromises);

  return deleteableSplitTransaction;
};

type Props = {
  scenario?: Scenario;
  isDeletingTransactionOrTransactionType: boolean;
  queryClient: QueryClient;
  householdId: string;
  transactions: ExpenseTransaction[];
  deleteSplitContract: ReturnType<typeof useDeleteSplitContract>['deleteSplitContract'];
  transactionTypesGroup: TransactionType[];
  isCreatingTransaction: boolean;
  deleteTransactionType: ReturnType<typeof useDeleteTransactionType>['deleteTransactionType'];
  onColumnDropdownRemove: OnColumnDropdownRemove;
  selectedPersonFromDropdown: string;
};

const removeTransactionType = async ({
  scenario,
  isDeletingTransactionOrTransactionType,
  queryClient,
  householdId,
  transactions,
  deleteSplitContract,
  transactionTypesGroup,
  isCreatingTransaction,
  deleteTransactionType,
  onColumnDropdownRemove,
  selectedPersonFromDropdown,
}: Props) => {
  const scenarioId = scenario?.id;
  const queryKey = transactionKey.expenses({ householdId, scenarioId });

  const canDeleteTransactions = !isCreatingTransaction;
  if (isDeletingTransactionOrTransactionType) return;

  if (canDeleteTransactions) {
    const deleteableSplitTransactions = getDeletabeSplitTransactions({
      transactionTypesGroup,
      transactions,
      scenarioId,
    });
    await Promise.all(
      deleteableSplitTransactions.map((transaction) =>
        deleteSplitContract({
          splitContractId: transaction.parentTransactionId,
          personId: transaction.personId,
        }),
      ),
    );

    const deleteTransactionTypePromises = transactionTypesGroup.reduce((acc, tt) => {
      const isSplitTransactionType = deleteableSplitTransactions.find(
        (transaction) => transaction.transactionTypeId === tt.id,
      );

      if (tt.underDropdown) {
        onColumnDropdownRemove(tt.id);
      } else if (!isSplitTransactionType) {
        acc.push(
          deleteTransactionType({
            id: tt.id,
            personId: tt.personId as string,
          }),
        );
      }
      return acc;
    }, [] as Promise<unknown>[]);

    await Promise.all(deleteTransactionTypePromises);
  }
  queryClient.invalidateQueries(queryKey);
  queryClient.invalidateQueries(
    transactionKey.computations({
      householdId,
      personId: selectedPersonFromDropdown,
      scenarioId,
    }),
  );
};

export default removeTransactionType;
