import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useParams } from 'react-router-dom';

import { initAccountingApi } from 'api';
import { TransactionTypesEnum } from 'utils';
import { transactionKey } from '@service/transaction';

interface DeleteSplitType {
  personId: string;
  splitContractId: string;
}
const useDeleteSplitContract = ({
  shouldRefetchExpenses = true,
}: {
  shouldRefetchExpenses?: boolean;
} = {}) => {
  const queryClient = useQueryClient();
  const { householdId } = useParams();
  const transactionTypeQueryKey = [
    `transaction-types`,
    { householdId, type: TransactionTypesEnum.Hhb },
  ];

  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({ splitContractId, personId }: DeleteSplitType) => {
      const queryKey = [`split-contract`, { personId }];
      const url = `/expense/split/${splitContractId}`;

      queryClient.invalidateQueries(queryKey);
      return initAccountingApi().delete<DeleteSplitType>(url, {
        params: { personId, householdId },
      });
    },
    {
      onSuccess: () => {
        const queryKey = transactionKey.expenses({ householdId, scenarioId: undefined });

        queryClient.invalidateQueries(transactionTypeQueryKey);
        if (shouldRefetchExpenses) queryClient.invalidateQueries(queryKey);
      },
    },
  );
  return {
    isError,
    isSuccess,
    isLoading,
    deleteSplitContract: mutateAsync,
  };
};

export default useDeleteSplitContract;
