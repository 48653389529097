import { useQuery } from '@tanstack/react-query';
import { DisplayGroupType } from 'types/displayGroup';

import { initAccountingApi } from 'api';
import { useParams } from 'react-router-dom';

interface Props {
  type: string;
  householdId: string;
}

const useDisplayGroupList = ({ type, householdId }: Props) => {
  const { scenarioId } = useParams();

  const queryKey = [`display-group-list`, { householdId, type }];
  const url = `v3/display-group?householdId=${householdId}&type=${type}`;
  const queryFn = async () =>
    initAccountingApi().get<DisplayGroupType[]>(url, {
      params: {
        scenarioId,
      },
    });

  const { data, isError, isLoading, isSuccess } = useQuery(queryKey, queryFn, {
    refetchOnMount: false,
    staleTime: Infinity,
    enabled: true,
  });

  return {
    isSuccess,
    isError,
    displayGroupList: data?.data || [],
    isLoadingDisplayGroupList: isLoading,
  };
};

export default useDisplayGroupList;
