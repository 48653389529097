import React, { FC } from 'react';
import { DxButton, DxText } from '@dvag/design-system-react';
import { i18n } from 'locales';

import Alert from '@components/Alert';

interface Props {
  body?: string;
  visible: boolean;
  onCancel: (() => void) | undefined;
  onConfirm: (() => void) | undefined;
}

const UnsavedFormAlert: FC<Props> = ({ onCancel, onConfirm, visible, body }) => (
  <Alert
    data-testid="moving-away-alert"
    actionsdirection="row"
    visible={visible}
    titlestring={i18n.t('attentionTitle')}
  >
    <DxText data-testid="alert-modal-text" slot="custom" type="Infotext-Standard">
      {body}
    </DxText>
    <DxButton
      data-testid="alert-leave-button"
      slot="action"
      type="primary-s"
      onClick={onConfirm}
      label={i18n.t('general.leave')}
    />
    <DxButton
      data-testid="alert-cancel-button"
      slot="action"
      type="text"
      onClick={onCancel}
      label={i18n.t('general.cancel')}
    />
  </Alert>
);

UnsavedFormAlert.defaultProps = {
  body: i18n.t('warnings.invalidDataWannaLeave'),
};

export default UnsavedFormAlert;
