import React, { FC, useRef, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useContinuationContext } from 'providers';
import { syncOrchestratorUrl } from '@dvag/dfs-orchestrator-client/navigation';
import UnsavedOrPendingPopup from 'components/UnsavedOrPendingPopup';

const Root: FC = () => {
  const isInitialMount = useRef(true);
  const location = useLocation();

  const { continuation, isSubmittingList, canContinueList } = useContinuationContext();

  const isSubmitting = isSubmittingList.some((item) => item);
  const canContinue = canContinueList.every((item) => item);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (location.key) {
      syncOrchestratorUrl(decodeURI(location.pathname));
    }
  }, [location]);

  return (
    <>
      <Outlet />
      <UnsavedOrPendingPopup
        showPending={continuation !== undefined && isSubmitting}
        showUnsaved={continuation !== undefined && !isSubmitting && !canContinue}
        onCancel={continuation?.onCancel}
        onConfirm={continuation?.onConfirm}
      />
    </>
  );
};

export default Root;
