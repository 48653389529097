import { Transaction, TransactionType } from 'types/transaction';
import filterTransactionsById from './filterTransactionsById';

const shouldExcludeHiddenTransactionType = (
  transactions: Transaction[],
  transactionType: TransactionType,
) => {
  if (typeof transactionType.isHidden !== 'boolean') {
    return false;
  }

  const transactionTypeTransactions = filterTransactionsById(transactions, transactionType);
  return transactionTypeTransactions.length === 0;
};
export default shouldExcludeHiddenTransactionType;
