import { FinancialGrade } from 'types/financialGrade';
import { useQuery } from '@tanstack/react-query';

import { initAccountingApi } from 'api';

interface Response {
  isError: boolean;
  financialGrade: FinancialGrade[];
  isLoadingFinancialGrade: boolean;
}

interface Props {
  personId: string;
  householdId: string;
}

const useFinancialGrade = ({ personId, householdId }: Props): Response => {
  const queryKey = [`financial-grade`, { householdId, personId }];
  const url = `/v3/financial-grade?householdId=${householdId}&personId=${personId}`;
  const queryFn = async () => initAccountingApi().get<FinancialGrade[]>(url);

  const { data, isError, isLoading } = useQuery(queryKey, queryFn, {
    enabled: !!personId && !!householdId,
  });

  return {
    isError,
    financialGrade: data?.data || [],
    isLoadingFinancialGrade: isLoading,
  };
};

export default useFinancialGrade;
