import React from 'react';
import { i18n } from 'locales';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DxButton, DxTextInput } from '@dvag/design-system-react';

import Modal from '@components/Modal';
import { useUpdateScenario } from '@hooks';
import useStore from '@store';
import {
  selectCurrentSimulation,
  selectSimulationAction,
  selectSimulationActions,
} from '@store/simulation/selector';

const EditSimulationNameModal = () => {
  const { clear } = useStore(selectSimulationActions);
  const action = useStore(selectSimulationAction);
  const current = useStore(selectCurrentSimulation);
  const { householdId } = useParams();
  const { mutate: updateScenario } = useUpdateScenario({ householdId, ...current });
  const { setValue, watch, register, formState, handleSubmit, resetField } = useForm({
    defaultValues: { name: current?.name },
    mode: 'onChange',
    values: { name: current?.name },
  });
  const { errors, isDirty, isValid } = formState;

  const registerSimulationName = register('name', {
    required: {
      value: true,
      message: i18n.t('validation.nameMustExist'),
    },
    maxLength: {
      value: 100,
      message: i18n.t('validation.maxLength', { maxLength: 100 }),
    },
    validate: (value) => (value && !!value.trim()) || i18n.t('validation.fieldRequired'),
  });

  const reset = () => {
    clear();
    resetField('name', { keepDirty: false });
  };

  const handleModalClose = (e) => {
    const modalBackgroundClicked = e.detail.reason === 'background';
    if (modalBackgroundClicked) {
      e.detail.preventCloseModal();
      return;
    }
    reset();
  };

  const handleChange = (event) => {
    setValue('name', event.detail, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const handleSave = async () => {
    if (!current) return;
    await handleSubmit((data) => updateScenario(data))();
    reset();
  };

  return (
    <Modal
      onModalClosed={handleModalClose}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      width="m"
      height="content"
      data-testid="modal-card"
      visible={action === 'update'}
      label={i18n.t('dashboard.scenariosCard.editScenarioModal.headline')}
    >
      <div slot="content">
        <DxTextInput
          placeholder=""
          value={watch('name')}
          onValueChange={handleChange}
          data-testid="name"
          label={i18n.t('dashboard.scenariosCard.editScenarioModal.label')}
          errormessage={errors.name?.message}
          ref={registerSimulationName.ref}
        />
      </div>
      <DxButton
        data-testid="save-name"
        type="primary-s"
        onClick={handleSave}
        slot="primary-actions"
        label={i18n.t('general.save')}
        disabled={!isDirty || !isValid}
      />
      <DxButton
        data-testid="modal-cancel-button"
        type="text"
        slot="secondary-actions"
        onClick={handleModalClose}
        label={i18n.t('general.cancel')}
      />
    </Modal>
  );
};

export default EditSimulationNameModal;
