import { i18n, getLocale } from 'locales';
import analytics from '@dvag/dfs-accounting-analytics';

import { decimalSeparator } from 'config/currencyPlaceholder';
import { FieldNameEnum } from 'config';

export const formatValue = (value: number, fieldName: FieldNameEnum) => {
  const formattedValue = value.toString().split('.').join(decimalSeparator.value);

  const units = {
    [FieldNameEnum.Wohnflaeche]: ' m²',
    [FieldNameEnum.JaehrlicheFahrleistung]: ' km',
    [FieldNameEnum.Zins]: ' %',
    [FieldNameEnum.Sparzins]: ' %',
    [FieldNameEnum.Progression]: ' %',
    [FieldNameEnum.AdditionalInterest]: ' %',
    [FieldNameEnum.InterestRateOfLoan]: ' %',
    [FieldNameEnum.Anteile]: '',
  };

  return units[fieldName]
    ? formattedValue + units[fieldName]
    : analytics.toCurrency(value, getLocale());
};

const validationErrorMessages = {
  requiredMessage: i18n.t('validation.fieldRequired'),
  setMinValueFieldError: (): string => i18n.t('validation.fieldMustBePositive'),
  setExceededMaxValueError: (value: number | undefined, fieldName?: string): string => {
    if (!value) return '';
    const maxValue = formatValue(value, fieldName as FieldNameEnum);
    return i18n.t('validation.exceededMaximumValue', { maxValue });
  },
};

export default validationErrorMessages;
