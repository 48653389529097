import { TransactionType, Transaction } from 'types/transaction';
import filterTransactionsById from './filterTransactionsById';
import findAllowedTransactionTypes from './findAllowedTransactionTypes';

const filterActiveTransactionTypes = (
  transactions: Transaction[],
  transactionTypes: TransactionType[],
) =>
  transactionTypes
    .filter((transactionType) => findAllowedTransactionTypes(transactions, transactionType))
    .filter((transactionType) => {
      const typeTransactions = filterTransactionsById(transactions, transactionType);

      return (
        transactionType.active || !transactionType.displayGroupParentId || typeTransactions.length
      );
    });

export default filterActiveTransactionTypes;
