import React, { FC, useState } from 'react';
import { i18n } from 'locales';
import { DxButton } from '@dvag/design-system-react';
import GalleryModal from './GalleryModal';
import { GalleryOption } from './config/galleryOptions';

interface Props {
  options: GalleryOption[];
}

const Gallery: FC<Props> = ({ options }) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  return (
    <div data-testid="more-information-wrapper" className="pr-6">
      <DxButton
        icon="information"
        type="text"
        iconposition="left"
        onClick={openModal}
        data-testid="more-information-button"
        label={i18n.t('general.moreInformation')}
      />

      <GalleryModal options={options} setShowModal={setShowModal} showModal={showModal} />
    </div>
  );
};

export default Gallery;
