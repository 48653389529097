import { useQueries, useQueryClient } from '@tanstack/react-query';
import { findTransactionComputations, transactionKey } from '@service/transaction';
import { useScenarios } from '../useScenarios';
import addSurplusToSimulationCache from './addSurplusToSimulationCache';

type Props = {
  personId?: string;
  householdId: string;
  enabled?: boolean;
};

const useScenarioComputeBatch = ({ householdId, personId }: Props) => {
  const { data: scenarios, isLoading: isLoadingScenarios } = useScenarios();
  const queryClient = useQueryClient();

  const queries = useQueries({
    queries:
      scenarios?.map((item) => ({
        queryFn: () =>
          findTransactionComputations({
            householdId,
            personId,
            scenarioId: item.id,
          }),
        queryKey: transactionKey.computations({
          householdId,
          personId,
          scenarioId: item.id,
        }),
        onSuccess: (data) => addSurplusToSimulationCache(queryClient, householdId, data),
        keepPreviousData: true,
        enabled: !!householdId && !isLoadingScenarios && !!scenarios,
      })) || [],
  });
  return queries;
};

export default useScenarioComputeBatch;
