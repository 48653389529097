import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { addInitListener } from '@dvag/dfs-orchestrator-client';

import App from './App';
import { getLocale } from './locales';
import './index.css';

const htmlEl = document.documentElement;
htmlEl.lang = getLocale();
const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

export const initializeApp = () => {
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
};

export const initializeOrchestrator = () => addInitListener(initializeApp);

initializeOrchestrator();
