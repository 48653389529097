import { Person } from '@store/person';

export interface PersonOption {
  label: string;
  value: string;
}

export const mapPersonsToPersonOptions = (persons: Person[]): PersonOption[] =>
  persons.map(({ syncId, firstName = '', lastName = '' }) => ({
    value: syncId,
    label: `${firstName} ${lastName}`,
  }));

export const addHouseholdToPersonOptions = (
  persons: Person[],
  householdId: string,
  householdLabel: string,
  householdOptionForHouseholdsWithOnePerson?: boolean,
): PersonOption[] => {
  const options = mapPersonsToPersonOptions(persons);
  if (options.length > 1 || householdOptionForHouseholdsWithOnePerson) {
    options.unshift({
      value: householdId,
      label: householdLabel,
    });
  }
  return options;
};
