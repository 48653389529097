import { useQuery, useQueryClient } from '@tanstack/react-query';
import { findTransactionComputations, transactionKey } from '@service/transaction';
import { useParams } from 'react-router-dom';
import { useScenarios } from '../useScenarios';
import addSurplusToSimulationCache from './addSurplusToSimulationCache';

type WithSimulationSource = 'firstFromList' | 'fromUrl';

type Props = {
  personId?: string;
  householdId: string;
  enabled?: boolean;
  withSimulationSource?: WithSimulationSource;
};

const useCompute = ({ householdId, personId, withSimulationSource, enabled }: Props) => {
  const { scenarioId: scenarioIdFromUrl } = useParams();

  const scenarios = useScenarios({ enabled: withSimulationSource === 'firstFromList' });
  const scenarioIdSource: { [key in WithSimulationSource]: string } = {
    firstFromList: scenarios.data?.[0]?.id || '',
    fromUrl: scenarioIdFromUrl,
  };
  const scenarioId = withSimulationSource ? scenarioIdSource[withSimulationSource] : undefined;

  const queryClient = useQueryClient();
  const queryKey = transactionKey.computations({ householdId, personId, scenarioId });

  return useQuery(
    queryKey,
    () =>
      findTransactionComputations({
        householdId,
        personId,
        scenarioId,
      }),
    {
      keepPreviousData: true,
      enabled: !!householdId && enabled,
      onSuccess: (data) => {
        if (withSimulationSource && withSimulationSource !== 'firstFromList') {
          addSurplusToSimulationCache(queryClient, householdId, data);
        }
      },
    },
  );
};

export default useCompute;
