import React, { FC, useCallback, useEffect } from 'react';
import { DxButton } from '@dvag/design-system-react';
import { useLocation, useParams } from 'react-router-dom';
import { updateComparisonSessionStorage } from '@dvag/dfs-financial-comparison';

import { i18n } from 'locales';
import { useContinuationContext } from 'providers';
import { useNavigation } from 'hooks';
import { usePersons, useScenarios, useCreateScenario } from '@hooks';
import filterScenariosByType from '@utils/other/filterScenariosByType';
import { SimulationType } from '@store/simulation';

type Props = {
  className: string;
};

const StartSimulationButton: FC<Props> = ({ className }) => {
  const { getContinuationHandler } = useContinuationContext();
  const { scenarioId, householdId } = useParams();
  const { persons } = usePersons();

  const location = useLocation();
  const { navigateCustom, navigateBack } = useNavigation();

  const { data: scenarios } = useScenarios();
  const { mutateAsync: createScenario } = useCreateScenario({ householdId, person: persons[0] });

  const startSimulation = useCallback(async () => {
    const filteredScenariosByType = filterScenariosByType(
      scenarios || [],
      SimulationType.FUTURE_HOUSEHOLD_BUDGET,
    );
    if (filteredScenariosByType.length > 0) {
      const latestScenario = filteredScenariosByType[0];
      const { id } = latestScenario;

      navigateCustom(`/simulation/${id}${location.pathname}`, { replace: true });
    } else {
      const response = await createScenario();
      const { id } = response.data;
      navigateCustom(`/simulation/${id}${location.pathname}`, {
        replace: true,
        state: { step: undefined },
      });
    }
  }, [scenarios, navigateCustom, location.pathname, createScenario]);

  useEffect(() => {
    updateComparisonSessionStorage('simulationMode', !!scenarioId);
  }, [scenarioId]);

  useEffect(() => {
    const scenarioMissing = !!scenarioId && !scenarios?.length;

    if (scenarioMissing) {
      const timeoutMS = 250;
      setTimeout(() => {
        navigateBack();
      }, timeoutMS);
    }
  }, [navigateBack, scenarios?.length, scenarioId]);

  return (
    <DxButton
      unfocusable
      type="text"
      icon="dokumente"
      iconposition="right"
      className={className}
      id="start-simulation-button"
      data-testid="start-simulation-button"
      label={i18n.t('hhb.simulation.start')}
      onClick={getContinuationHandler(startSimulation)}
    />
  );
};

export default StartSimulationButton;
