import { i18n } from 'locales';
import * as yup from 'yup';

import {
  HousingPropertyTypes,
  OwnRealEstateArtEnum,
  OwnRealEstateNutzungEnum,
} from 'types/housingInformation';
import { createValidationSchema } from './validationSchema';

export const ownRealEstateArtOptions = Object.keys(OwnRealEstateArtEnum).map((value) => ({
  value,
  label: i18n.t(`housing.whatToBuyOptions.${value}` as unknown as TemplateStringsArray),
}));
export const ownRealEstateNutzungOptions = Object.keys(OwnRealEstateNutzungEnum).map((value) => ({
  value,
  label: i18n.t(`housing.nutzungsart.${value}` as unknown as TemplateStringsArray),
}));

export const options = [
  {
    label: i18n.t('housing.whatToBuyOptions.EINFAMILIENHAUS'),
    value: HousingPropertyTypes.EINFAMILIENHAUS,
  },
  {
    label: i18n.t('housing.whatToBuyOptions.EIGENTUMSWOHNUNG'),
    value: HousingPropertyTypes.EIGENTUMSWOHNUNG,
  },
  {
    label: i18n.t('housing.whatToBuyOptions.FERIENWOHNUNG'),
    value: HousingPropertyTypes.FERIENWOHNUNG,
  },
  {
    label: i18n.t('housing.whatToBuyOptions.GESCHAEFTSGEBAEUDE'),
    value: HousingPropertyTypes.GESCHAEFTSGEBAEUDE,
  },
  {
    label: i18n.t('housing.whatToBuyOptions.UNBEBAUTES_GRUNDSTUECK'),
    value: HousingPropertyTypes.UNBEBAUTES_GRUNDSTUECK,
  },
  {
    label: i18n.t('housing.whatToBuyOptions.MEHRFAMILIENHAUS'),
    value: HousingPropertyTypes.MEHRFAMILIENHAUS,
  },
  {
    label: i18n.t('housing.whatToBuyOptions.ZWEIFAMILIENHAUS'),
    value: HousingPropertyTypes.ZWEIFAMILIENHAUS,
  },
  {
    label: i18n.t('housing.whatToBuyOptions.DOPPELHAUSHAELFTE'),
    value: HousingPropertyTypes.DOPPELHAUSHAELFTE,
  },
  {
    label: i18n.t('housing.whatToBuyOptions.ETAGENWOHNUNG'),
    value: HousingPropertyTypes.ETAGENWOHNUNG,
  },
  {
    label: i18n.t('housing.whatToBuyOptions.REIHENHAUS'),
    value: HousingPropertyTypes.REIHENHAUS,
  },
  {
    label: i18n.t('housing.whatToBuyOptions.EINFAMILIENHAUS_MIT_EINLIEGERWOHNUNG'),
    value: HousingPropertyTypes.EINFAMILIENHAUS_MIT_EINLIEGERWOHNUNG,
  },
  {
    label: i18n.t('housing.whatToBuyOptions.ERBBAUGRUNDSTUECK'),
    value: HousingPropertyTypes.ERBBAUGRUNDSTUECK,
  },
  {
    label: i18n.t('housing.whatToBuyOptions.FERTIGHAUS_FIRMA'),
    value: HousingPropertyTypes.FERTIGHAUS_FIRMA,
  },
];

export const isObjectFalsy = (object: Record<string, unknown>) =>
  !Object.values(object).some(Boolean);

export const defaultOwnRealEstateForm = {
  art: null,
  name: null,
  nutzung: null,
  verkehrswert: null,
  personId: null,
};

export const defaultBuyRealEstateForm = {
  capital: null,
  price: null,
  siteExists: null,
  whatToBuy: null,
  whenToBuy: null,
  personId: null,
};
type OwnRealEstateFormType = yup.InferType<
  ReturnType<typeof createValidationSchema>['ownRealEstate']
>;
type OwnRealEstateFormKeys = keyof OwnRealEstateFormType;
export type OwnRealEstateFormField = { [key in OwnRealEstateFormKeys]: key };
