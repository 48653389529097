import { Person } from '@store/person';
import { useRouteLoaderData } from 'react-router-dom';

interface Props {
  persons: Person[];
}

const usePersons = (): Props => {
  const data = useRouteLoaderData('root');
  return data ? (data as Props) : { persons: [] };
};

export default usePersons;
