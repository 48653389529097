import { getLocale } from 'locales';

const locale = getLocale();

const getLocaleDateString = (date: Date) =>
  date.toLocaleDateString(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

export default getLocaleDateString;
