import { ExpenseTransaction, TransactionType } from 'types/transaction';

const groupTransactionsAndTransactionTypes = (
  transactionTypesGroup: TransactionType[],
  transactions: ExpenseTransaction[],
) =>
  transactionTypesGroup.map((tt) => ({
    transactionType: tt,
    transactions: transactions.filter((transaction) => transaction.transactionTypeId === tt.id),
  }));

export default groupTransactionsAndTransactionTypes;
