import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { HttpStatusCodeEnum } from 'config';

const MAX_QUERY_RETRIES = 5;

export const defaultQueriesOptions = {
  useErrorBoundary: (error) =>
    (error as AxiosError).response?.status === HttpStatusCodeEnum.FORBIDDEN,
};

const queryConfig = (show): QueryClient =>
  new QueryClient({
    mutationCache: new MutationCache({
      onError: (error) => {
        show(
          'toast',
          (error as AxiosError).response?.status.toString(),
          (error as AxiosError).message,
        );
      },
    }),
    queryCache: new QueryCache({
      onError: (error) => {
        show(
          'toast',
          (error as AxiosError).response?.status.toString(),
          (error as AxiosError).message,
        );
      },
    }),
    defaultOptions: {
      queries: {
        refetchInterval: false,
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
          if ((error as AxiosError).response?.status === HttpStatusCodeEnum.CONFLICT) {
            return false;
          }
          return (
            failureCount <= MAX_QUERY_RETRIES &&
            (error as AxiosError).response?.status !== HttpStatusCodeEnum.FORBIDDEN
          );
        },
        ...(process.env.NODE_ENV === 'test' ? {} : defaultQueriesOptions),
      },
      mutations: {
        useErrorBoundary: (error) =>
          (error as AxiosError).response?.status === HttpStatusCodeEnum.FORBIDDEN,
      },
    },
  });

export default queryConfig;
