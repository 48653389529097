import React, { FC, useMemo } from 'react';
import Alert from '@components/Alert';
import { DxButton } from '@dvag/design-system-react';
import { useNavigation } from 'hooks';
import { i18n } from 'locales';
import { useContinuationContext } from 'providers';

import { useLocation } from 'react-router-dom';
import { getDefaultPath } from 'utils';
import { useResetScenario, usePersons } from '@hooks';
import { selectSimulationAction, selectSimulationActions } from '@store/simulation/selector';
import useStore from '@store';
import { Scenario } from '@store/simulation';
import findPersonByPersonIdInScenario from '@utils/other/findPersonByPersonIdInScenario';

interface Props {
  scenario: Scenario;
}

const ResetSimulationAlert: FC<Props> = (props) => {
  const { scenario } = props;
  const { persons } = usePersons();

  const location = useLocation();
  const action = useStore(selectSimulationAction);
  const defaultPath = useMemo(() => getDefaultPath(location.pathname), [location.pathname]);
  const { clear } = useStore(selectSimulationActions);

  const { getContinuationHandler } = useContinuationContext();
  const { navigateCustom } = useNavigation();

  const { mutateAsync: resetScenario, isLoading: isResetScenarioLoading } = useResetScenario({
    type: scenario?.type,
    person: findPersonByPersonIdInScenario(scenario, persons),
    scenario,
  });

  const resetSimulation = async () => {
    clear();
    const response = await resetScenario();
    const { id } = response.data;
    navigateCustom(`/simulation/${id}${defaultPath}`, { replace: true });
  };

  return (
    <>
      <Alert
        data-testid="resseting-simulation-alert"
        visible={isResetScenarioLoading}
        content={i18n.t('hhb.simulation.oneMoment')}
        titlestring={
          action === 'reset'
            ? i18n.t('hhb.simulation.resettingSimulation')
            : i18n.t('hhb.simulation.preparingSimulation')
        }
      >
        <DxButton slot="action" type="primary-s" data-testid="hidden-alert-action" hidden />
      </Alert>
      <Alert
        data-testid="scenario-loading"
        actionsdirection="row"
        icon="information"
        titlestring={i18n.t('hhb.simulation.resetSimulationInformation')}
        content={i18n.t('hhb.simulation.confirmResetSimulation')}
        visible={action === 'reset' && !isResetScenarioLoading}
      >
        <DxButton
          slot="action"
          type="primary-s"
          icon="wiedergabe"
          iconposition="right"
          data-testid="reset-simulation-alert"
          label={i18n.t('hhb.simulation.yes')}
          onClick={getContinuationHandler(resetSimulation)}
        />
        <DxButton
          type="text"
          slot="action"
          data-testid="reset-simulation-alert"
          label={i18n.t('hhb.simulation.no')}
          onClick={clear}
        />
      </Alert>
    </>
  );
};

export default ResetSimulationAlert;
