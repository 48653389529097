import { initPersonApi } from 'api';
import { Person } from '@store/person';

export type HouseholdResponse = {
  id: string;
  personInHouseholdList: Person[];
};

export const householdKey = {
  byId: (householdId: string) => ['household', { householdId } as const],
};

export async function getHouseholdQueryFn(householdId: string): Promise<HouseholdResponse> {
  return initPersonApi()
    .get<HouseholdResponse>(`/v3/household/${householdId}`)
    .then(({ data }) => data);
}

export default getHouseholdQueryFn;
