import { DisplayGroupType } from 'types/displayGroup';
import { TransactionType } from 'types/transaction';

const groupTransactionTypeListByDisplayGroup = (
  transactionTypes: TransactionType[],
  displayGroups: DisplayGroupType[],
): { [key: string]: TransactionType[] } => {
  const groupedData = displayGroups.reduce(
    (acc, displayGroup) => ({ ...acc, [displayGroup.key]: [] }),
    {},
  );

  transactionTypes.forEach((transactionType) => {
    const { displayGroupId, displayGroupParentId } = transactionType;

    const currentDisplayGroup = displayGroups.find(
      (displayGroup) =>
        displayGroup.id === displayGroupId || displayGroup.id === displayGroupParentId,
    );

    const currentKey = currentDisplayGroup?.key;
    groupedData[currentKey ?? 0]?.push(transactionType);
  });

  return groupedData;
};

export default groupTransactionTypeListByDisplayGroup;
