import React, { FC } from 'react';
import { useLocation, Outlet, useParams } from 'react-router-dom';
import { DxApp, DxText, DxTabBar, DxTabPage, DxCardContent } from '@dvag/design-system-react';
import { i18n } from 'locales';

import { useScenarios } from '@hooks';
import { HHBTab } from '@store/contract';
import { useNavigation } from 'hooks';
import PersonSelector from '@components/PersonSelector';
import config, { HHB_TABS } from 'screens/householdBudget/config';
import StartSimulationButton from '@components/layout/components/StartSimulationButton';

const HHBLayout: FC = () => {
  const { householdId } = useParams();
  const location = useLocation();
  const { navigateTo } = useNavigation();
  const { data: scenario } = useScenarios();

  const simulationMode = !scenario;
  const tabList = config({ simulationMode });
  const activeTab = location.pathname.split('haushaltsbudget/')[1];
  const activeTabConfig = tabList.find((tabConfig) => activeTab.includes(tabConfig.id)) as HHBTab;
  const onTabChange = (e) => {
    const path = e.detail === HHB_TABS.ausgaben ? 'analysis.expenses' : 'analysis.contractOverview';
    return navigateTo(path, { householdId });
  };
  return (
    <div
      className="mx-auto max-w-layout px-4 sm:px-6 md:px-8 lg:px-8 xl:px-10"
      data-testid="HHBP-card"
    >
      <div>
        <div className="my-6 flex flex-col gap-2 md:flex-row md:flex-wrap md:items-center md:justify-between">
          <DxText type="h3" slot="title" color="gray-83" data-testid="HHB-header">
            {activeTabConfig.title}
          </DxText>
          <PersonSelector householdOptionLabel={i18n.t('hhb.contractOverview.showHousehold')} />
        </div>
        <DxApp>
          <DxTabBar
            className="relative w-full justify-between"
            onTabChange={onTabChange}
            data-testid="HHB-tabs-list"
            selected={activeTabConfig.id}
          >
            {activeTabConfig.id === HHB_TABS.ausgaben && (
              <div className="absolute right-0 top-3">
                <StartSimulationButton className="hidden md:block" />
              </div>
            )}
            {tabList.map((tabConfig) => (
              <DxTabPage
                key={tabConfig.id}
                value={tabConfig.id}
                label={tabConfig.label}
                data-testid={`HHB-tabs-tab-${tabConfig.id}`}
              >
                <DxCardContent className="p-0" data-testid={`HHB-tab-card-content-${tabConfig.id}`}>
                  <Outlet />
                </DxCardContent>
              </DxTabPage>
            ))}
          </DxTabBar>
        </DxApp>
      </div>
    </div>
  );
};

export default HHBLayout;
