import React, { FC } from 'react';
import { DxText } from '@dvag/design-system-react';
import { i18n } from 'locales';

import { Modal } from '@components';

interface Props {
  visible: boolean;
  onCancel: (() => void) | undefined;
}

const PendingRequestModal: FC<Props> = ({ visible, onCancel }) => (
  <Modal
    onModalClosed={onCancel}
    height="content"
    data-testid="moving-away-alert"
    visible={visible}
  >
    <div slot="content" className="text-center">
      <DxText id="savingData" type="ps">
        {i18n.t('warnings.savingData')}
      </DxText>
    </div>
  </Modal>
);

export default PendingRequestModal;
