import React, { FC } from 'react';
import { DxButton } from '@dvag/design-system-react';

import useStore from '@store';

import { Scenario } from '@store/simulation';
import EditSimulationNameModal from './EditSimulationNameModal';

interface Props {
  simulation: Scenario;
  className: string;
}

const EditSimulationName: FC<Props> = ({ simulation, className }) => {
  const { update } = useStore((state) => state.simulation.actions);

  return (
    <div data-testid="edit-name-wrapper" className={className}>
      <DxButton
        icon="edit"
        type="text"
        iconposition="left"
        onClick={() => update(simulation, 'update')}
        data-testid="edit-button"
      />

      <EditSimulationNameModal />
    </div>
  );
};

export default EditSimulationName;
