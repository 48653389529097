import React, { FC } from 'react';
import { DxOverflowMenu, DxOverflowMenuItem } from '@dvag/design-system-react';

import useStore from '@store';
import { usePersons } from '@hooks';
import { selectCurrentPerson, selectPersonActions } from '@store/person/selector';
import { mapPersonsToPersonOptions, addHouseholdToPersonOptions, checkIsMobile } from 'utils';
import { Person } from '@store/person';

interface Option {
  value: string;
  label: string;
}

interface Props {
  householdOptionLabel?: string;
  householdOptionForHouseholdsWithOnePerson?: boolean;
  selected?: string;
  handleSelection?: (value: string) => void;
  showOnlyFirstTwoPersons?: Person[];
}

const PersonSelector: FC<Props> = ({
  showOnlyFirstTwoPersons,
  selected,
  handleSelection,
  householdOptionLabel,
  householdOptionForHouseholdsWithOnePerson,
}) => {
  const { persons } = usePersons();
  const isMobile = checkIsMobile();
  const currentPerson = useStore(selectCurrentPerson);

  const { setCurrentPerson } = useStore(selectPersonActions);
  const options: Option[] =
    householdOptionLabel && persons.length > 1
      ? addHouseholdToPersonOptions(
          (showOnlyFirstTwoPersons as Person[]) || persons,
          '',
          householdOptionLabel,
          householdOptionForHouseholdsWithOnePerson,
        )
      : mapPersonsToPersonOptions((showOnlyFirstTwoPersons as Person[]) || persons);

  const selectedValue = selected || currentPerson;
  const selectedOption = options.find((option) => option.value === selectedValue);

  const handleOverflowMenuSelection = ({ detail }) =>
    handleSelection ? handleSelection(detail.value) : setCurrentPerson(detail.value as string);

  return (
    <DxOverflowMenu
      data-testid="person-selector"
      label={selectedOption?.label}
      align={isMobile ? 'left' : 'right'}
      onSelection={handleOverflowMenuSelection}
      className="md:flex-end mt-0 md:flex md:flex-col md:justify-end md:self-end"
    >
      {options.map(({ value, label }, index) => (
        <DxOverflowMenuItem
          key={value}
          id={value}
          value={value}
          label={label}
          data-testid={`person-selector-item-${index}`}
        />
      ))}
    </DxOverflowMenu>
  );
};

PersonSelector.defaultProps = {
  selected: undefined,
  handleSelection: undefined,
  householdOptionLabel: undefined,
  showOnlyFirstTwoPersons: undefined,
  householdOptionForHouseholdsWithOnePerson: true,
};

export default PersonSelector;
