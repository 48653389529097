import { UseMutateAsyncFunction, useMutation, useQueryClient } from '@tanstack/react-query';

import { TransactionTypesEnum } from 'utils';
import { TransactionType } from 'types/transaction';

import { initAccountingApi } from 'api';
import { useParams } from 'react-router-dom';

interface ResponseType {
  isLoading: boolean;
  isSuccess: boolean;
  isCreateTransactionError: boolean;
  createdTransactionType: TransactionType;
  createTransactionType: UseMutateAsyncFunction<unknown, unknown, unknown, unknown>;
}
interface CacheType {
  data: TransactionType[];
}

const useCreateTransactionType = (householdId: string): ResponseType => {
  const queryClient = useQueryClient();
  const { scenarioId } = useParams();
  const mutationKey = [
    `transaction-types`,
    { householdId, type: TransactionTypesEnum.Hhb, scenarioId },
  ];
  const url = 'v4/transaction-type';

  const queryFn = (requestData) =>
    initAccountingApi().post(
      url,
      { ...requestData, householdId },
      {
        params: {
          scenarioId,
        },
      },
    );
  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(queryFn, {
    mutationKey,
    onSuccess: (response) => {
      const cacheData: CacheType = (queryClient.getQueryData(mutationKey) as CacheType) || {
        data: [],
      };
      queryClient.setQueryData(mutationKey, {
        data: [...cacheData.data, response.data],
      });
      queryClient.invalidateQueries(mutationKey);
    },
  });

  return {
    isLoading,
    isSuccess,
    isCreateTransactionError: isError,
    createTransactionType: mutateAsync,
    createdTransactionType: data?.data as TransactionType,
  };
};

export default useCreateTransactionType;
