import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useParams } from 'react-router';

import { TransactionTypesEnum } from 'utils';
import { TransactionTotal } from 'types/transaction';

import { initAccountingApi } from 'api';
import { ApiResponse } from '@dvag/dfs-api-client/interface';

interface ResponseType {
  isLoadingTransactionsTotal: boolean;
  isError: boolean;
  transactionsTotal: TransactionTotal;
}

const useTransactionsTotal = (type = TransactionTypesEnum.Income): ResponseType => {
  const { householdId, scenarioId } = useParams();

  const queryKey = ['transaction-total', { householdId, type, scenarioId }];
  const url = `v4/transaction/total?householdId=${householdId}&type=${type}`;

  const { data, isError, isLoading } = useQuery(
    queryKey,
    async () =>
      initAccountingApi().get<TransactionTotal>(url, {
        params: {
          scenarioId,
        },
      }),
    {
      cacheTime: 0,
      select: useCallback(
        (responseData: ApiResponse<TransactionTotal>): { data: TransactionTotal } => ({
          data: responseData.data || {},
        }),
        [],
      ),
    },
  );

  return {
    isLoadingTransactionsTotal: isLoading,
    isError,
    transactionsTotal: data?.data || ({} as TransactionTotal),
  };
};

useTransactionsTotal.defaultProps = {
  type: TransactionTypesEnum.Income,
};

export default useTransactionsTotal;
