import React, { Suspense, FC } from 'react';
import { I18nextProvider } from 'react-i18next';
import { QueryClientProvider } from '@tanstack/react-query';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

import useStore from '@store';

import { i18n } from 'locales';
import { queryConfig, getAppInsights } from '@lib';
import featureFlag from '@service/featureFlag';

interface AppProviderProps {
  children: React.ReactNode;
}

const AppProvider: FC<AppProviderProps> = ({ children }) => {
  const { show } = useStore((state) => state.feedback.actions);
  featureFlag.init();
  return (
    <AppInsightsContext.Provider value={getAppInsights().reactPlugin}>
      <GrowthBookProvider growthbook={featureFlag.gb}>
        <I18nextProvider i18n={i18n}>
          <QueryClientProvider client={queryConfig(show)}>
            <Suspense fallback={null}>{children}</Suspense>
          </QueryClientProvider>
        </I18nextProvider>
      </GrowthBookProvider>
    </AppInsightsContext.Provider>
  );
};

export default AppProvider;
