import { QueryKey, QueryClient } from '@tanstack/react-query';
import { ExpenseTransaction, TransactionComputation } from 'types/transaction';
import { transactionKey } from '@service/transaction';

interface OnMutateTransactionsComputationsCacheUpdateForExpense {
  householdId: string;
  scenarioId: string;
  queryClient: QueryClient;
  updatedTransaction: ExpenseTransaction;
}

interface UpdateTransactionsComputationsCacheForExpense
  extends Omit<
    OnMutateTransactionsComputationsCacheUpdateForExpense,
    'shouldUpdateForHousehold' | 'householdId' | 'scenarioId'
  > {
  queryKey: QueryKey;
}

export interface PrevCacheDataItem {
  prevCache: TransactionComputation | undefined;
  queryKey: QueryKey;
}
type PrevCacheDataArray = PrevCacheDataItem[];

const updateTransactionsComputationsCacheForExpense = ({
  queryKey,
  queryClient,
  updatedTransaction,
}: UpdateTransactionsComputationsCacheForExpense): PrevCacheDataItem => {
  const cache = queryClient.getQueryData<{ data: TransactionComputation }>(queryKey)?.data;
  const isNewAmountFixed = updatedTransaction.isFixed;
  const amountAfterTaxes = (updatedTransaction.amountAfterTaxes as number) || 0;
  const monthly = cache?.financialGradeScore?.current.LEBEN_KONSUM.monthly;
  if (monthly) {
    const { fixedAmount, variableAmount } = monthly;
    const newFixedAmount = (isNewAmountFixed ? 1 : -1) * amountAfterTaxes + fixedAmount;
    const newVariableAmount = (isNewAmountFixed ? -1 : 1) * amountAfterTaxes + variableAmount;

    const updatedCache: TransactionComputation = {
      ...cache,
      financialGradeScore: {
        ...cache.financialGradeScore,
        current: {
          ...cache.financialGradeScore.current,
          LEBEN_KONSUM: {
            ...cache.financialGradeScore.current.LEBEN_KONSUM,
            monthly: {
              ...monthly,
              fixedAmount: newFixedAmount,
              variableAmount: newVariableAmount,
            },
          },
        },
      },
    };

    queryClient.setQueryData(queryKey, { data: updatedCache });
  }

  return { prevCache: cache, queryKey };
};

export const onMutateTransactionsComputationsCacheUpdateForExpense = ({
  householdId,
  scenarioId,
  queryClient,
  updatedTransaction,
}: OnMutateTransactionsComputationsCacheUpdateForExpense): {
  prevTransactionsComputationsCacheArr: PrevCacheDataArray;
} => {
  const queryKeyPerson = transactionKey.computations({
    householdId,
    personId: updatedTransaction.personId,
    scenarioId,
  });
  const queryKeyHousehold = transactionKey.computations({
    householdId,
    personId: '',
    scenarioId,
  });

  const prevCacheDataPerson = updateTransactionsComputationsCacheForExpense({
    queryKey: queryKeyPerson,
    queryClient,
    updatedTransaction,
  });

  const prevCacheDataHousehold = updateTransactionsComputationsCacheForExpense({
    queryKey: queryKeyHousehold,
    queryClient,
    updatedTransaction,
  });
  const prevTransactionsComputationsCacheArr = [prevCacheDataPerson, prevCacheDataHousehold];
  return { prevTransactionsComputationsCacheArr };
};

export const onErrorTransactionsComputationsCacheUpdateForExpense = ({
  queryClient,
  prevTransactionsComputationsCacheArr,
}: {
  queryClient: QueryClient;
  prevTransactionsComputationsCacheArr?: PrevCacheDataArray;
}) => {
  if (prevTransactionsComputationsCacheArr && Array.isArray(prevTransactionsComputationsCacheArr)) {
    prevTransactionsComputationsCacheArr.forEach(({ prevCache, queryKey }) => {
      queryClient.setQueryData(queryKey, { data: prevCache });
    });
  }
};
