import { useEffect } from 'react';

const useResetPageScroll = () => {
  useEffect(
    () => () => {
      const { body } = document;
      body.style.overflow = 'auto';
    },
    [],
  );
};

export default useResetPageScroll;
