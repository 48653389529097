import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Scenario, SimulationType } from '@store/simulation';

import { createScenario, deleteScenario, scenariosKey } from '@service/scenario';
import { Person } from '@store/person';

interface CacheType {
  data: Scenario[];
}
interface Props {
  type?: SimulationType;
  person: Person;
  scenario: Scenario;
}

const useResetScenario = ({ type, person, scenario }: Props) => {
  const queryClient = useQueryClient();
  const { householdId, scenarioId } = useParams();
  const mutationKey = scenariosKey.byHousehold(householdId);

  const resetScenario = async () => {
    await deleteScenario(scenarioId, scenario.personId);
    return createScenario(person, householdId, type);
  };

  return useMutation(resetScenario, {
    onSuccess: (response) => {
      const cacheData: CacheType = queryClient.getQueryData(mutationKey) as CacheType;
      queryClient.setQueryData(mutationKey, {
        ...cacheData,
        data: [response?.data],
      });
      queryClient.invalidateQueries(mutationKey);
    },
  });
};

export default useResetScenario;
