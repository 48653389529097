import { deleteScenario, scenariosKey } from '@service/scenario';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useDeleteScenario = ({ householdId }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: scenariosKey.byHousehold(householdId),
    mutationFn: ({ id, personId }: { id: string; personId: string }) =>
      deleteScenario(id, personId),
    onSuccess: () => {
      queryClient.invalidateQueries(scenariosKey.byHousehold(householdId));
    },
  });
};
export default useDeleteScenario;
