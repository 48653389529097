import { ExpenseTransaction } from 'types/transaction';
import { Scenario } from '@store/simulation';

export const isTransactionInfoChangedInSimulation = ({
  scenario,
  transaction,
}: {
  scenario?: Scenario;
  transaction: ExpenseTransaction;
}) => {
  if (scenario && transaction) {
    const isTransactionSeeded = transaction.seedValue;
    const isTransactionAmount = transaction.amountAfterTaxes || 0;
    const stringifiedPersonId = `${transaction.personId}`;
    const stringifiedSeedPersonId = `${isTransactionSeeded?.personId}`;
    const isTransactionSeededAmount = isTransactionSeeded?.amountAfterTaxes;
    const isNewTransactionPositive = isTransactionAmount > 0;

    const changedInSim = isTransactionSeeded
      ? isTransactionAmount !== isTransactionSeededAmount ||
        stringifiedPersonId !== stringifiedSeedPersonId
      : isNewTransactionPositive;
    return changedInSim;
  }
  return false;
};

export const isTransactionTypeInfoChangedInSimulation = ({
  scenario,
  transactions,
  sumTransactionsValueAfterTaxes,
  sumSeedTransactionValueAfterTaxes,
}: {
  scenario?: Scenario;
  transactions: ExpenseTransaction[];
  sumTransactionsValueAfterTaxes: number;
  sumSeedTransactionValueAfterTaxes: number;
}) => {
  if (scenario) {
    const isTotalValueChanged =
      sumTransactionsValueAfterTaxes !== sumSeedTransactionValueAfterTaxes;
    const isAnyTransactionChanged = transactions.some((transaction) =>
      isTransactionInfoChangedInSimulation({ scenario, transaction }),
    );

    return isAnyTransactionChanged || isTotalValueChanged;
  }

  return false;
};
