import { UseMutateAsyncFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResponse } from '@dvag/dfs-api-client/interface';

import { initAccountingApi } from 'api';
import { TransactionTypesEnum } from 'utils';
import { TransactionType } from 'types/transaction';
import { useParams } from 'react-router-dom';
import { AxiosRequestConfig } from 'axios';

interface Props {
  type?: TransactionTypesEnum;
  reloadData?: boolean;
  householdId: string;
}

interface MutationArgType {
  id: string | undefined;
  personId: string;
}

interface TransactionTypesResponseType {
  isError: boolean;
  deleteTransactionType: UseMutateAsyncFunction<
    ApiResponse<Record<string, unknown> | undefined, unknown>,
    unknown,
    MutationArgType,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
}

const useDeleteTransactionType = ({
  reloadData = false,
  householdId,
}: Props): TransactionTypesResponseType => {
  const { scenarioId } = useParams();

  const queryClient = useQueryClient();
  const mutationKey = [
    `transaction-types`,
    { householdId, type: TransactionTypesEnum.Hhb, scenarioId },
  ];
  const { mutateAsync, isError, isLoading, isSuccess } = useMutation(
    async ({ id, personId }: MutationArgType) => {
      const url = `v4/transaction-type/${id}?householdId=${householdId}&personId=${personId}`;
      return initAccountingApi().delete<Record<string, unknown>>(url, {
        params: {
          scenarioId,
        },
      });
    },

    {
      onMutate: async (variables) => {
        const { id } = variables;
        await queryClient.cancelQueries(mutationKey);
        const previousDataQuery =
          queryClient.getQueryData<{ data: TransactionType[] }>(mutationKey)?.data || [];
        const newData = previousDataQuery.filter((item) => item.id !== id);
        queryClient.setQueryData(
          mutationKey,
          (cache: AxiosRequestConfig<Record<string, unknown>>['data']) => ({
            ...cache,
            data: newData,
          }),
        );
        return { previousDataQuery };
      },
      onSuccess: () => {
        if (reloadData) {
          queryClient.invalidateQueries(mutationKey);
        }
      },
    },
  );

  return {
    deleteTransactionType: mutateAsync,
    isError,
    isLoading,
    isSuccess,
  };
};

export default useDeleteTransactionType;
