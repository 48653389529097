export enum FieldNameEnum {
  Occurrences = 'occurrences',
  Zins = 'zins',
  Sparzins = 'sparzins',
  Wohnflaeche = 'wohnflaeche',
  JaehrlicheFahrleistung = 'jaehrlicheFahrleistung',
  Progression = 'versichertePersonen.0.progression',
  AdditionalInterest = 'additionalData.interest',
  InterestRateOfLoan = 'additionalData.interestRateOfLoan',
  Anteile = 'anteile',
}

export enum HttpStatusCodeEnum {
  SUCCESS = 200,
  BAD_REQUEST = 400,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
}
